<template>
    <div v-if="folder">
        <div v-for="(item) in folder">
            <div v-if="item.itemType==='folder'">
                <div class="component-view-list-body__item framed">
                    <div @click="toggle(item.id, $event)" class="component-view-list-body__item-content" style="cursor: pointer;">
                        <div class="component-view-list-body__row">
                            <div class="share-files-tr file-infos">
                                <div class="file-icon combined">
                                    <div class="file-icon-symbol">
                                        <i v-show="openIds.includes(item.id)" :class="['fas fa-2x fa-folder-open']" :data-id="item.id"></i>
                                        <i v-show="!openIds.includes(item.id)" :class="['fas fa-2x fa-folder']" :data-id="item.id"></i>
                                    </div>
                                    <div class="file-icon-badge">{{item.fileCount}}</div>
                                </div>
                                <div class="file-info-columns">
                                    <div class="file-name">
                                        <div class="share-files-th">{{ $t('download.files.labels.name')}}</div>
                                        <div class="share-files-td">{{ item.name }}</div>
                                    </div>
                                    <div class="file-size">
                                        <div class="share-files-th">{{ $t('download.files.labels.size')}}</div>
                                        <div class="share-files-td">{{ helper.formatFileSize(item.size, globals.selected_language) }}</div>
                                    </div>
                                </div>
                                <div class="selection-box">
                                    <label class="custom-checkbox-container">
                                        <input type="checkbox" @change.stop="file_selection($event);" :data-id="item.id" :data-name="item.name" :checked="globals.selected_folderIds.find(d=>d === item.id)">
                                        <span class="checkmark"></span>
                                    </label>
                                </div>
                            </div>
                        </div>
                        <div class="component-view-list-body__row" style="justify-content: flex-end; align-items: baseline; ">
                            <div class="share-files-tr dl-button">
                                <button type="button" class="button small" @click="get_download_url($event)" :data-id="item.id" :data-filename="item.name">{{ $t('download.files.labels.download_folder')}}</button>
                            </div>
                        </div>
                    </div>
                </div>
                <FileShareItem v-show="openIds.includes(item.id)" style="padding-left:27px;" :folder="item.children"></FileShareItem>
            </div>
            <div v-else class="component-view-list-body__item framed">
                <div class="component-view-list-body__item-content">
                    <div class="component-view-list-body__row">
                        <div class="share-files-tr file-infos">
                            <div class="file-icon combined">
                                <div class="file-icon-symbol">
                                    <i :class="['fas fa-2x', helper.getFileIconByContentType(item.contentType)]"></i>
                                </div>
                            </div>
                            <div class="file-info-columns">
                                <div class="file-name">
                                    <div class="share-files-th">{{ $t('download.files.labels.name')}}</div>
                                    <div class="share-files-td">{{ item.name }}</div>
                                </div>
                                <div class="file-size">
                                    <div class="share-files-th">{{ $t('download.files.labels.size')}}</div>
                                    <div class="share-files-td">{{ helper.formatFileSize(item.size, globals.selected_language) }}</div>
                                </div>
                            </div>
                            <div class="selection-box">
                                <label class="custom-checkbox-container">
                                    <input type="checkbox" @change.stop="file_selection($event);" :data-id="item.id" :data-name="item.name" :checked="globals.selected_files.find(d=>d.id === item.id)">
                                    <span class="checkmark"></span>
                                </label>
                            </div>
                        </div>
                    </div>

                    <div class="component-view-list-body__row" style="justify-content: flex-end; align-items: baseline; ">
                        <div class="share-files-tr dl-button">
                            <button type="button" class="button small" @click="this.get_download_url($event)" :data-id="item.id" :data-filename="item.name">{{ $t('download.files.labels.download_file')}}</button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    import helper from '../utils/Helper';
    import globals from '../utils/Globals';
    export default {
        name: 'FileShareItem',
        setup() {
            return {
                helper, globals,
            };
        },
        props: {
            folder: '',
        },
        data() {
            return {
                isOpen: false,
                openIds: [],
            };
        },
        inject: [
            'file_selection',
            'get_download_url',
        ],
        
        methods: {
            toggle(id, event) {
                if (event.target.tagName === 'SPAN' || event.target.tagName === 'INPUT' || event.target.tagName === 'BUTTON')
                    return;

                //console.log("==========================ToogleEvent===[" + id + "]============", event.target.tagName, event);
               
                if (this.openIds.includes(id))
                    this.openIds = this.openIds.filter(d => d !== id);
                else
                    this.openIds.push(id);
                //console.log("openIds",this.openIds);

            }
        }
    }
</script>

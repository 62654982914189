<template>
    <div v-if="folder">
        <div v-for="(item) in folder">
            <div v-if="item.itemType==='folder'">
                <div @click="toggle($event)" :data-id="item.id" class="selectedfiles-item folder" :class="{'error': item.visibleOptions.fileSizeExceeded}" :title="$t ('errors.upload_folder_too_large_tooltipp', {max_size: helper.formatFileSize(globals.max_file_size, globals.selected_language)})">
                    <div class="selectedfiles-item-icon combined" :data-id="item.id">
                        <div class="file-icon-symbol">
                            <i v-show="openIds.includes(item.id)" :class="['fas fa-lg fa-folder-open']" :data-id="item.id"></i>
                            <i v-show="!openIds.includes(item.id)" :class="['fas fa-lg fa-folder']" :data-id="item.id"></i>
                        </div>
                        <div class="file-icon-badge">{{item.fileCount}}</div>
                    </div>
                    <div class="selectedfiles-item-name" :data-id="item.id">{{ item.name }}</div>
                    <div class="selectedfiles-item-size" :data-id="item.id">{{ helper.formatFileSize(item.size, globals.selected_language) }}</div>
                    <div class="selectedfiles-item-button button small-square toolbar" :class="{'disabled': is_confirmation_required_view_visible}" @click="removeSelectedFileById(item.id)">
                        <i style="color: #ffffff;" class="fa-solid fa-trash-can-xmark"></i>
                    </div>
                </div>
                <FolderItem v-show="openIds.includes(item.id)" style="padding-left:18px;" :folder="item.children"></FolderItem>
            </div>
            <div v-else class="selectedfiles-item" :class="{'error': item.visibleOptions.fileSizeExceeded}" :title="$t ('errors.upload_file_too_large_tooltipp', {max_size: helper.formatFileSize(globals.max_file_size, globals.selected_language)})">
                <div class="selectedfiles-item-icon combined">
                    <div class="file-icon-symbol">
                        <i :class="['fas fa-lg', helper.getFileIconByContentType(item.contentType)]"></i>
                    </div>
                </div>
                <div class="selectedfiles-item-name">{{ item.name }} {{item.visibleOptions.fileSizeExceeded}}</div>
                <div class="selectedfiles-item-size">{{helper.formatFileSize(item.size, globals.selected_language) }}</div>
                <div class="selectedfiles-item-button button small-square toolbar" :class="{'disabled': is_confirmation_required_view_visible}" @click="removeSelectedFileById(item.id)">
                    <i style="color: #ffffff;" class="fa-solid fa-trash-can-xmark"></i>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    import helper from '../utils/Helper';
    import globals from '../utils/Globals';
    export default {
        name: 'FolderItem',
        setup() {
            return {
                helper, globals,
            };
        },
        props: {
            folder: '',
        },
        data() {
            return {
                isOpen: false,
                openIds:[],

            };
        },
        inject: [
            'removeSelectedFileById',
            'is_confirmation_required_view_visible'
        ], 
        methods: {
            toggle(event) {
                //console.log("bin im toggle", event);
                const target = event.target;
                const id = target.getAttribute('data-id');                
                
                if (this.openIds.includes(id))
                    this.openIds = this.openIds.filter(d => d !== id);
                else
                    this.openIds.push(id);
                //console.log("openIds",this.openIds);

            }
        }
    }
</script>

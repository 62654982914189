<template>
    <transition name="fade" @after-leave="handleAfterLeave">
        <div v-if="visible" class="notification" :class="level">
            {{ message }}
        </div>
    </transition>
</template>

<script>
    export default {
        props: ['message', 'duration', 'level'],
        data() {
            return {
                visible: false
            };
        },
        mounted() {
            this.show();
        },
        methods: {
            show() {
                this.visible = true;
                setTimeout(() => {
                    this.visible = false;
                }, this.duration);
            },
            handleAfterLeave() {
                this.$emit('after-leave');
            }
        }
    };
</script>

<style scoped>
    .fade-enter-active {
        transition: opacity 0.8s;
    }
    .fade-leave-active {
        transition: opacity 1.5s;
    }
    .fade-enter, .fade-leave-to /* .fade-leave-active in <2.1.8 */ {
        opacity: 0;
    }

    .notification {
        position: fixed;
        left: 50%;
        transform: translateX(-50%);
        z-index: 1000;
        background-color: #444;
        color: #000;
        font-weight:600;
        padding: 10px;
        border-radius: 6px;
        margin-top: 10px;
        width: 500px;
        /*box-shadow: rgba(0,0,0,0.1) 2px 2px*/
    }

        .notification.error {
            background-color: #F0A0A099;
        }

        .notification.success {
            background-color: #60c83899;
        }

        .notification.neutral {
            background-color: #D8D8D899;
            color: #000;
        }
</style>

<template>
    <div class="passwordstrength">
        <ul>
            <li :class="{ 'text-success': passwordLength }">{{ $t('passwordstrength.passwordLength')}}</li>
            <li :class="{ 'text-success': specialCharacter }">{{ $t('passwordstrength.specialCharacter')}}</li>
            <li :class="{ 'text-success': uppercaseLetter }">{{ $t('passwordstrength.uppercaseLetter')}}</li>
            <li :class="{ 'text-success': lowercaseLetter }">{{ $t('passwordstrength.lowercaseLetter')}}</li>
            <li :class="{ 'text-success': digit }">{{ $t('passwordstrength.digit')}}</li>
        </ul>
    </div>
</template>
<script>
    export default {
        name: 'CheckPassword',
        props: {
            confirm_email_code: '',
        },
        data: () => ({
            passwordLength: false,
            specialCharacter: false,
            uppercaseLetter: false,
            lowercaseLetter: false,
            digit: false,
        }),
        methods: {
            checkPasswordStrength(password) {
                // �berpr�fen der Passwortanforderungen
                this.passwordLength = password.length >= 8;
                this.specialCharacter = /[!@#$%^&*(),.?":{}|<>]/.test(password);
                this.uppercaseLetter = /[A-Z]/.test(password);
                this.lowercaseLetter = /[a-z]/.test(password);
                this.digit = /\d/.test(password);

                return this.passwordLength && this.specialCharacter && this.uppercaseLetter && this.lowercaseLetter && this.digit;
            }
        },
        mounted: function () {
        },
    }
</script>
<template>
    <div class="custom-dropdown" :class="{'open': isOpen}">
        <div @click="isOpen = !isOpen" class="selected-option">
            {{ $t(selectedOption.label ||defaultLabel) }}
            <span :class="{'open': isOpen}">
            </span>
            <i v-if="!isOpen" class="fas fa-chevron-down"></i>
            <i v-if="isOpen" class="fas fa-chevron-up"></i>
        </div>
        <div v-if="isOpen" class="options">
            <div class="option" v-for="option in optionsList"
                 :key="option.value"
                 @click="selectOption(option)">
                {{ $t(option.label) }}
            </div>
        </div>
    </div>
</template>

<script>
    export default {
        
        props: {    
            optionsList : '',             
            defaultLabel : '',
            },
        //props:
        //{
        //    optionsList: [],
        // },
        data: () => ({
            
                isOpen: false,
                selectedOption: { label : null, value : null},
             
        }),
        methods: {
            selectOption(option) {
                this.selectedOption = option;
                this.isOpen = false;
                this.$emit('option-selected', option);
            }
        }
    };
</script>

<style>
    .custom-dropdown {
        position: relative;
        /*width: 200px;*/
        width: auto;
        height: 29px;
        /* UI Properties */
        background: #EFEFEF 0% 0% no-repeat padding-box;
        border-radius: 6px;
        opacity: 1;
        font-size: 13.3333px;
        text-align: left;
        letter-spacing: 0px;
        color: #666666;
        opacity: 1;
        padding: 2px 0px 2px 9px;
        border: none;
        line-height: 1em;
        box-shadow: 0.5px 0.5px 0px rgba(0, 0, 0, 0.5);
    }
        .custom-dropdown.open {
            border-radius: 6px 6px 0px 0px;
        }
        .selected-option, .options div {
        padding: 8px 8px 8px 2px;
        /*border: 1px solid #ccc;*/
        }
            .selected-option span {
                position: absolute;
                background-color: #4796C1;
                right: 0;
                top: 0;
                width: 45px;
                height: 33px;
                border-radius: 0px 6px 6px 0px;
            }
            .selected-option span.open {

                border-radius: 0px 6px 0px 0px;
            }
        .selected-option i {
            position: absolute;
            color: #ffffff;
            right: 15px;
            top: 50%;
            transform: translateY(-50%);
        }
    .custom-dropdown div.options {
        position: absolute;
        left: 0;
        top: 33px;
        width: 100%;
        z-index: 888;
        background-color: #FFFFFF;
        border-radius: 0px 0px 6px 6px;
        box-shadow: 0.5px 0.5px 1px rgba(0, 0, 0, 0.5);
    }

        .custom-dropdown div.options div.option {
            padding: 8px 0px 8px 9px;
            border-radius: 6px;
            margin: 2px;
        }
            .custom-dropdown div.options div.option:hover {
                background-color: #4796C1;
                color: #ffffff;
            }
</style>

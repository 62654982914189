<template>
    <div id="fileShare">
        <div class="component-view-list">
            <div class="component-view-list-body">
                <div class="component-view-list-body__item more_space">
                    <div class="component-view-list-body__item-content">
                        <div class="component-view-list-body__row more_space" v-if="from_address">
                            <div class="component-view-list-body__label">{{ $t('download.header.labels.from')}}</div>
                            <div class="component-view-list-body__content">{{ from_address }}</div>
                        </div>
                        <div class="component-view-list-body__row more_space" v-if="to_address">
                            <div class="component-view-list-body__label">{{ $t('download.header.labels.to')}}</div>
                            <div class="component-view-list-body__content">{{ to_address }}</div>
                        </div>
                    </div>
                </div>
                <div class="component-view-list-body__item more_space">
                    <div class="component-view-list-body__item-content">
                        <div class="component-view-list-body__row more_space" v-if="subject_text">
                            <div class="component-view-list-body__label" v-if="to_address">{{ $t('download.header.labels.subject')}}</div>
                            <div class="component-view-list-body__label" v-if="!to_address">{{ $t('download.header.labels.subject_link')}}</div>
                            <div class="component-view-list-body__content">{{ subject_text }}</div>
                        </div>
                        <div class="component-view-list-body__row" v-if="message_text" :class="{'more_space': !is_message_overflowing}">
                            <div class="component-view-list-body__label">{{ $t('download.header.labels.message')}}</div>
                            <div class="component-view-list-body__content message-item" ref="message_item" :class="{'expanded':is_message_expanded}">{{ message_text }}</div>
                        </div>
                        <div class="component-view-list-body__row more_space" v-if="is_message_overflowing">
                            <div class="component-view-list-body__label"></div>
                            <div class="component-view-list-body__content">
                                <a class="button xsmall square toolbar" @click="is_message_expanded = !is_message_expanded">
                                    <span v-if="!is_message_expanded">{{ $t('general.buttons.show_more')}}</span>
                                    <span v-if="is_message_expanded">{{ $t('general.buttons.show_less')}}</span>
                                </a>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="component-view-list-body__item more_space">
                    <div class="component-view-list-body__item-content">
                        <div class="component-view-list-body__row more_space" v-if="created_on">
                            <div class="component-view-list-body__label" v-if="to_address">{{ $t('download.header.labels.created_on')}}</div>
                            <div class="component-view-list-body__label" v-if="!to_address">{{ $t('download.header.labels.created_on_link')}}</div>
                            <div class="component-view-list-body__content">{{helper.formatReadableDate(created_on, globals.selected_language) }}</div>
                        </div>
                        <div class="component-view-list-body__row more_space" v-if="expiry_date">
                            <div class="component-view-list-body__label">{{ $t('download.header.labels.expiry_date')}}</div>
                            <div class="component-view-list-body__content">{{ helper.formatReadableDate(expiry_date, globals.selected_language) }}</div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="component-view-list">
            <div class="component-view-list-header" v-if="this.files.length > 1">
                <div class="component-view-list-filter">
                    <div class="search-container">
                        <input class="search-input" type="text" v-model="searchInput" autocomplete="off" :placeholder="$t('download.listview.toolbarpanel.filter.search.placeholder')">
                        <button class="search-button"><i class="fa fa-search"></i></button>
                    </div>
                </div>
                <div class="component-view-list-sort">
                    <CustomDropdown :optionsList="sortOptions" :defaultLabel="'download.listview.toolbarpanel.sort.placeholder'" @option-selected="handleSortSelected"></CustomDropdown>
                </div>
            </div>
            <div v-if="this.filteredFiles.length > 0" class="component-view-list-body scrollable">
                <FileShareExplorer :filesTree="filteredFilesTree"></FileShareExplorer>
            </div>
            <div v-else class="component-view-list-body">
                <div class="component-view-list-body__item-content">
                    {{ $t('download.listview.no_content')}}
                </div>
            </div>
            <div class="component-view-list-footer button-right">
                <div class="component-view-list-footer__actions" v-if="this.filteredFiles.length>1">
                    <button class="button small toolbar wide" v-if="globals.selected_files.length!=this.filteredFiles.length" @click="select_all_files()">{{$t('download.listview.toolbarpanel.selection.select_all')}}</button>
                    <button class="button small toolbar wide" v-else @click="unselect_all_files()">{{$t('download.listview.toolbarpanel.selection.remove')}}</button>
                </div>
            </div>
            <div class="component-view-list-footer">

                <div class="component-view-list-footer__actions" v-if="globals.selected_files.length>1">
                    <div style="padding-bottom: 9px;">{{$t('download.listview.toolbarpanel.selection.summary', {selected_files_length: globals.selected_files.length})}}</div>
                </div>
                <div class="component-view-list-footer__actions" v-if="globals.selected_files.length>1">
                    <button type="button" class="button small wide" @click="this.download_and_zip_selected_files(globals.selected_files)">{{$t('download.listview.toolbarpanel.selection.button')}}</button>

                </div>

            </div>
        </div>
    </div>
</template>
<script>
    import CustomDropdown from './CustomDropdown.vue';
    import FileShareExplorer from '../components/FileShareExplorer.vue';

    import helper from '../utils/Helper';
    import globals from '../utils/Globals';
    import security from '../utils/Security';

    import { zip } from 'fflate';

    export default {
        name: 'FileShare',
        setup() {
            return {
                helper, globals, security
            };
        },
        components: {
            CustomDropdown, FileShareExplorer,
        },
        props: {
            fileShareInfos: '',
            share_pw: ''
        },
        computed: {
            filteredFiles() {
                //globals.selected_files = [];
                let workingList = this.files;
                if (this.searchInput) {
                    workingList = workingList.filter(obj => obj.name.toLowerCase().includes(this.searchInput.toLowerCase()) || obj.relativePathDirOnly.toLowerCase().includes(this.searchInput.toLowerCase())
                    );
                }
                return workingList;
            },
            filteredFilesTree() {
                return helper.buildFileTreeFromFlatFileArray(this.filteredFiles, this.sortOption);
            }
        },
        data: () => ({
            id: '',
            message_text: '',
            subject_text: '',
            from_address: '',
            to_address: '',
            expiry_date: '',
            created_on: '',
            files: [],
            
            is_message_expanded: false,
            is_message_overflowing: false,            

            searchInput: '',
            sortOptions: [],
            sortOption: ''
        }),
        provide() {
            return {
                file_selection: this.file_selection,
                get_download_url: this.get_download_url,
            };
        },
        methods: {

            async save_download_content(fileShareInfos) {               

                this.files = await security.supplement_download_content(fileShareInfos, this.share_pw);
                this.password_required = false;
            },
            async get_download_url(event) {

                const target = event.target;
                const id = target.getAttribute('data-id');
                if (id.startsWith("folder_")) {
                    const idsToDownloadSet = helper.listAllItemIdsInFolder(id, this.filteredFilesTree);
                    const relFiles = this.files.filter(file => idsToDownloadSet.has(file.id));
                    this.download_and_zip_selected_files(relFiles);
                }
                else {
                    const filename = target.getAttribute('data-filename');

                    try {
                        var fileInfo = this.files.find(d => d.id == id);
                        const blob = await this.download_blob(fileInfo);
                        if (blob) {
                            //console.log(blob);
                            const url = URL.createObjectURL(blob);
                            const link = document.createElement('a');
                            link.href = url;
                            link.download = filename;
                            document.body.appendChild(link);
                            link.click();
                            document.body.removeChild(link);
                            URL.revokeObjectURL(url);
                            globals.hide_loading();
                        }
                    } catch (e) {
                        console.error(e);
                    }
                    finally {
                        this.globals.hide_loading();
                    }
                }
            },
            async download_blob(fileInfo) {

                const url = "files/generate-url";
                const data = { id: fileInfo.id, token: fileInfo.token, };
                const response = await globals.make_Xhr_Request("POST", url, data);
                if (response.status === 200) {
                    var result = JSON.parse(response.responseText);

                    const dl_response = await globals.make_Xhr_AbortableRequest("GET", result.payload.url, null, "blob");
                    if (dl_response.status === 200) {
                        var blob = dl_response.response;
                        if (fileInfo.is_encrypted) {
                            var edbk = security.encrypted_database_keys.find(d => d.database_key_id == fileInfo.database_key_id);
                            const dbkey = await security.process_decryption_as_crypto_key(edbk.encrypted_database_key, edbk.database_key_iv, security.current_share_key)
                            blob = await security.process_decryption_file_blob(blob, fileInfo, dbkey)
                        }
                        return blob;
                    }
                } else if (response.status === 408) {
                    globals.show_error('errors.files_expired');
                } else if (response.status === 451) {
                    globals.show_error('errors.generateUrl_451_file', { filename: fileInfo.name});
                } else if (response.status === 400) {
                    globals.show_error('errors.invalid_link');
                } else if (response.status === 404) {
                    globals.show_error('errors.files_not_found');
                } else {
                    globals.handle_Xhr_Errors(response.status, response.responseText);
                }
            },
            select_all_files() {
                globals.selected_files = this.filteredFiles;
                globals.selected_folderIds = helper.findCompleteFolders(this.filteredFilesTree, globals.selected_files.map(d => d.id));
            },
            unselect_all_files() {
                globals.selected_files = [];
                globals.selected_folderIds = [];
            },
            file_selection(event) {
                event.stopPropagation();
                const target = event.target;
                let id = target.getAttribute('data-id');
                let name = target.getAttribute('data-name');
                if (id.startsWith("folder_")) {
                    const idsToSelectSet = helper.listAllItemIdsInFolder(id, this.filteredFilesTree);
                    const relFiles = this.files.filter(file => idsToSelectSet.has(file.id));
                    relFiles.forEach(fileInfo => {
                            if (target.checked) {
                                globals.selected_files.push(fileInfo);
                            } else {
                                globals.selected_files = globals.selected_files.filter(item => item.id !== fileInfo.id);
                            }
                    });
                    if (target.checked) {
                        globals.trigger_notification(this.$i18next.t('download.listview.toolbarpanel.selection.add_notification', { filename: name }), "neutral");
                    } else {
                        globals.trigger_notification(this.$i18next.t('download.listview.toolbarpanel.selection.remove_notification', { filename: name }), "neutral");
                    }

                } else {
                    var fileInfo = this.files.find(d => d.id == id);
                    if (fileInfo) {
                        if (target.checked) {
                            globals.selected_files.push(fileInfo);
                            globals.trigger_notification(this.$i18next.t('download.listview.toolbarpanel.selection.add_notification', { filename: fileInfo.name }), "neutral");
                        } else {
                            globals.selected_files = globals.selected_files.filter(item => item.id !== id);
                            globals.trigger_notification(this.$i18next.t('download.listview.toolbarpanel.selection.remove_notification', { filename: fileInfo.name }), "neutral");
                        }
                    }
                }
                //console.log("globals.selected_files", globals.selected_files);

                globals.selected_folderIds = helper.findCompleteFolders(this.filteredFilesTree, globals.selected_files.map(d => d.id));
                //console.log("globals.selected_folderIds", globals.selected_folderIds);
            },
            
            prepareFilesForZipping(files) {

                let tree = helper.buildFileTreeFromFlatFileArray(files);

                helper.ensureUniqueNames(tree);
                let flattenFiles = helper.flattenFileTree(tree);

                const result = [];
                files.forEach(file => {
                     var uniqueNameFile = flattenFiles.find(d => d.id === file.id);
                    let name = file.name;
                    let pathToZip = file.relativePath;
                    if (uniqueNameFile) {
                        name = uniqueNameFile.name;
                        pathToZip = uniqueNameFile.path;
                    }
                    
                    // F�ge die Datei mit dem vollst�ndigen Pfad hinzu
                    result.push({
                        ...file,
                        pathToZip: pathToZip,
                        name: name
                    });
                });
                return result;
            },
            async download_and_zip_selected_files(selected_files) {
                try {
                    if (selected_files.length > 1) {
                        const zipObj = {};

                        const selected_files_prepared = this.prepareFilesForZipping(selected_files);
                        
                        for (const fileInfo of selected_files_prepared) {
                            const blob = await this.download_blob(fileInfo);
                            if (blob) {
                                if (fileInfo.pathToZip) {
                                    zipObj[fileInfo.pathToZip] = new Uint8Array(await blob.arrayBuffer());
                                } else {
                                    zipObj[fileInfo.name] = new Uint8Array(await blob.arrayBuffer());
                                }
                            }
                        }
                        console.log("zipObj", zipObj);
                        const toZipCount = Object.keys(zipObj).length;
                        if (toZipCount > 0) {
                            zip(zipObj, (err, zipped) => {
                                if (err) throw err;

                                const blob = new Blob([zipped], { type: 'application/zip' });
                                const url = URL.createObjectURL(blob);
                                const link = document.createElement('a');
                                link.href = url;
                                link.download = 'FileDirect_Share_' + toZipCount + '_Files_' + helper.getCurrentDateFormatted() + '.zip';
                                document.body.appendChild(link);
                                link.click();
                                document.body.removeChild(link);
                                URL.revokeObjectURL(url);
                            });
                        }
                    }
                } catch (e) {
                    console.error(e);
                    globals.show_error('errors.files_not_found');
                }
                finally {
                    this.globals.hide_loading();
                }

            },
            handleSortSelected(selectedOption) {
                this.sortOption = { sortName: selectedOption.value.name, sortOrder: selectedOption.value.order };
            },
            check_MessageItem_Overflow() {
                const message_item = this.$refs.message_item;
                if (message_item) {
                    this.is_message_overflowing = message_item.scrollHeight > message_item.clientHeight;
                }
            },
        },
        mounted: async function () {
            
            this.sortOptions =
                [
                    { label: 'download.listview.toolbarpanel.sort.options.by_size_asc', value: { name: "size", order: "ASC" } },
                    { label: 'download.listview.toolbarpanel.sort.options.by_size_desc', value: { name: "size", order: "DESC" } },
                    { label: 'download.listview.toolbarpanel.sort.options.by_name_asc', value: { name: "name", order: "ASC" } },
                    { label: 'download.listview.toolbarpanel.sort.options.by_name_desc', value: { name: "name", order: "DESC" } },
                ];

            await this.save_download_content(this.fileShareInfos);
            this.check_MessageItem_Overflow();
        },
    }
</script>
import i18next from 'i18next'
import I18NextVue from 'i18next-vue'
import LanguageDetector from 'i18next-browser-languagedetector'

// Importieren der Lokalisierungsdateien
import translationDE from '@common_locales/de/translation.json';
import translationEN from '@common_locales/en/translation.json';


export function initI18next(app) {
    i18next
        .use(LanguageDetector)
        .init({
            resources: {
                en: {
                    translation: translationEN
                },
                de: {
                    translation: translationDE
                }
            },
            debug: true,
            fallbackLng: 'en',
            interpolation: {
                escapeValue: false, // optional, um HTML-Tags in �bersetzungen zuzulassen
            },
            detection: {
                order: ['localStorage', 'navigator'], // Priorisiere localStorage vor navigator
                lookupLocalStorage: 'i18nextLng', // Definiere den Schl�ssel f�r localStorage
                caches: ['localStorage'],
            },
        });

    // i18next mit der Vue-App verbinden
    app.use(I18NextVue, { i18next });
}
<template>
    <div class="modal" v-show="globals.errors.length > 0">
        <div id="error-dialog" class="dialog-panel error">
            <div class="error-header">{{ $t('error_dialog.headline')}}</div>
            <div v-if="globals.errors.length>1">
                <ul class="error-items">
                    <li v-for="error in globals.errors" class="error-item">
                        <div v-html="error"></div>
                    </li>
                </ul>
            </div>
            <div v-else>
                <div class="error-items">
                    <div v-for="error in globals.errors" class="error-item">
                        <div v-html="error"></div>
                    </div>
                </div>
            </div>
            <div style="padding-top: 20px;"><button type="button" class="button" @click="globals.errors=[];">{{ $t('error_dialog.cancel_button')}}</button></div>
        </div>
    </div>
</template>
<script>
    import globals from '../utils/Globals';
    export default {
        name: 'ErrorDialog',
        setup() {
            return {
                globals
            };
        },
        props: {
            
        },
        data: () => ({
            
        }),
        methods: {
            handleKeyDown(event) {
                if (event.key === 'Escape' || event.key === 'Enter') {
                    if (this.globals.errors.length > 0) {
                        this.globals.errors = [];
                    }
                }
                
            },

        },
        mounted: function () {
            document.addEventListener('keydown', this.handleKeyDown);
        },
        beforeDestroy() {
            document.removeEventListener('keydown', this.handleKeyDown);
        },
    }
</script>
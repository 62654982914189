<template>
    <div id="fileShare">
        <div class="component-view-list">
            <div class="component-view-list-body">
                <div class="component-view-list-body__item more_space">
                    <div class="component-view-list-body__item-content">
                        <div class="component-view-list-body__row more_space" v-if="from_address">
                            <div class="component-view-list-body__label">{{ $t('secure_string.download.header.labels.from')}}</div>
                            <div class="component-view-list-body__content">{{ from_address }}</div>
                        </div>
                        <div class="component-view-list-body__row more_space" v-if="to_address">
                            <div class="component-view-list-body__label">{{ $t('secure_string.download.header.labels.to')}}</div>
                            <div class="component-view-list-body__content">{{ to_address }}</div>
                        </div>
                    </div>
                </div>
                <div class="component-view-list-body__item more_space">
                    <div class="component-view-list-body__item-content">
                        <div class="component-view-list-body__row more_space" v-if="created_on">
                            <div class="component-view-list-body__label" v-if="to_address">{{ $t('secure_string.download.header.labels.created_on')}}</div>
                            <div class="component-view-list-body__label" v-if="!to_address">{{ $t('secure_string.download.header.labels.created_on_link')}}</div>
                            <div class="component-view-list-body__content">{{helper.formatReadableDate(created_on, globals.selected_language) }}</div>
                        </div>
                        <div class="component-view-list-body__row more_space" v-if="expiry_date">
                            <div class="component-view-list-body__label">{{ $t('secure_string.download.header.labels.expiry_date')}}</div>
                            <div class="component-view-list-body__content">{{ helper.formatReadableDate(expiry_date, globals.selected_language) }}</div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="component-view-list">
            <div v-if="this.filteredFiles.length>0" class="component-view-list-body">
                <div v-for="file in this.filteredFiles" :key="file.id" class="component-view-list-body__item framed">
                    <div class="component-view-list-body__item-content">
                        <div v-if="!is_the_qr_code_visible" class="component-view-list-body__row">
                            <div v-if="is_the_secure_string_visible" class="secure-string" ref="secure_string" v-html="the_secure_string" style=""></div>
                            <div v-if="!is_the_secure_string_visible" class="secure-string">
                                <i class="fa-solid fa-star-of-life"></i> 
                                <i class="fa-solid fa-star-of-life"></i> 
                                <i class="fa-solid fa-star-of-life"></i> 
                                <i class="fa-solid fa-star-of-life"></i>
                            </div>
                        </div>
                        <div v-if="is_the_qr_code_visible" class="component-view-list-body__row">
                            <div class="secure-string" >
                                <qrcode :value="the_secure_string" :color="{ dark: '#000000', light: '#ffffff' }" errorCorrectionLevel="Q" :width="200" type="image/png"></qrcode>
                            </div>
                        </div>
                        <div v-if="is_the_secure_string_visible" class="component-view-list-body__row button-bar">
                            <div class="countdown-bage">{{hiding_countdown_view}}</div>
                            <button v-if="!is_the_qr_code_visible && copyToClipBoardButton_state == 0" type="button" @click="copyToClipboard();" class="button square toolbar"><i class="fa-solid fa-copy"></i></button>
                            <button v-if="!is_the_qr_code_visible && copyToClipBoardButton_state == 1" type="button" @click="copyToClipboard();" class="button square toolbar"><i class="fa-solid fa-check"></i></button>
                            <button v-if="!is_the_qr_code_visible && copyToClipBoardButton_state == -1" type="button" class="button square toolbar"><i class="fa-solid fa-square-exclamation"></i></button>
                            <button v-if="!is_the_qr_code_visible" type="button" class="button square toolbar" @click="show_qrcode();"><i class="fa-solid fa-qrcode"></i></button>
                            <button v-if="is_the_qr_code_visible" type="button" class="button square toolbar" @click="hide_qrcode();"><i class="fa-solid fa-message-text"></i></button>
                            <button type="button" class="button square toolbar countdown-button" @click="hide_secure_string();"><i class="fa-solid fa-eye-slash"></i></button>
                        </div>

                        <div v-if="!is_the_secure_string_visible" class="component-view-list-body__row button-right">
                            <button v-if="!is_the_secure_string_visible" type="button" class="button wide" @click="show_secure_string($event)" :data-id="file.id">{{ $t('secure_string.download.content.labels.open')}}</button>
                            <button v-if="is_the_secure_string_visible" type="button" class="button wide" @click="hide_secure_string();" :data-id="file.id">{{ $t('secure_string.download.content.labels.hide')}}</button>
                        </div>
                    </div>
                </div>
            </div>
            <div v-else class="component-view-list-body">
                <div class="component-view-list-body__item-content">
                    {{ $t('download.listview.no_content')}}
                </div>
            </div>
        </div>
    </div>
</template>
<script>
    import Qrcode from 'vue-qrcode';

    import helper from '../utils/Helper';
    import globals from '../utils/Globals';
    import security from '../utils/Security';

    export default {
        name: 'SecureStringShare',
        setup() {
            return {
                helper, globals, security
            };
        },
        components: {
            Qrcode,
        },
        props: {
            fileShareInfos: '',
            share_pw: ''
        },
        computed: {
            filteredFiles() {
                return this.files;
            },
        },
        data: () => ({
            id: '',
            from_address: '',
            to_address: '',
            expiry_date: '',
            created_on: '',
            files: [],

            is_the_secure_string_visible: false,
            copyToClipBoardButton_state: false,
            the_secure_string: '****',
            is_the_qr_code_visible: false,
            hiding_countdown: 10,            
            hiding_countdown_view: '',
            hiding_intervalId: null,
            
            


            defaultFontSize: 30, // Startgr��e in Pixel
            minFontSize: 18, // Minimale Schriftgr��e in Pixel
        }),
        methods: {

            async save_download_content(fileShareInfos) {
                
                this.id = fileShareInfos.id;
                this.from_address = fileShareInfos.from_address;
                this.to_address = fileShareInfos.to_address;
                this.expiry_date = fileShareInfos.expiry_date;
                this.created_on = fileShareInfos.created_on;
                const share_salt = fileShareInfos.share_salt;
                security.encrypted_database_keys = fileShareInfos.encrypted_database_keys;

                if (share_salt && this.share_pw) {

                    security.current_share_key = await security.generate_personal_key(this.share_pw, share_salt);

                } else if (security.personal_key){
                    security.current_share_key = security.personal_key;
                } else {
                    globals.show_error("errors.invalid_code");
                }
                this.files = fileShareInfos.files;
            },
            show_qrcode() {
                this.is_the_qr_code_visible = true;
                this.startCountdown();
            },
            hide_qrcode() {
                this.is_the_qr_code_visible = false;
                this.adjustFontSize();
                this.startCountdown();
            },
            hide_secure_string() {
                this.is_the_qr_code_visible = false;
                this.the_secure_string = '****';
                //this.$nextTick(() => {
                //    const container = this.$refs.secure_string[0];
                //    container.style.textAlign = `center`;
                //    let currentFontSize = this.defaultFontSize;
                //    container.style.fontSize = `${currentFontSize}px`
                //});
                this.is_the_secure_string_visible = false;
                this.stopCountdown();
            },
            async show_secure_string(event) {
                const target = event.target;
                const id = target.getAttribute('data-id');
                try {
                    var fileInfo = this.files.find(d => d.id == id);
                    const blob = await this.download_blob(fileInfo);
                    const text = await this.blobToString(blob);
                    this.the_secure_string = text;
                    this.is_the_secure_string_visible = true;
                    this.adjustFontSize();
                    this.startCountdown();
                } catch (e) {
                    console.error(e);
                }
                finally {
                    this.globals.hide_loading();
                }
            },
            
            formatSeconds(seconds) {
                // Stelle sicher, dass 'seconds' ein String ist und f�ge eine f�hrende Null hinzu, falls n�tig
                const formattedSeconds = seconds.toString().padStart(2, '0');
                return `00:${formattedSeconds}`;
            },
            stopCountdown() {
                clearInterval(this.hiding_intervalId); // Stoppe den Interval, wenn der Countdown 0 erreicht
                this.hiding_countdown = 10;
                this.hiding_countdown_view = this.formatSeconds(this.hiding_countdown);
                this.hiding_intervalId = null;
            },
            startCountdown() {
                this.stopCountdown();
                this.hiding_intervalId = setInterval(() => {
                    this.hiding_countdown -= 1;
                    if (this.hiding_countdown <= 0) {
                        this.stopCountdown();
                        this.hide_secure_string(); // F�hre eine Aktion aus, wenn der Countdown beendet ist
                    }
                    this.hiding_countdown_view = this.formatSeconds(this.hiding_countdown);
                    console.log(this.hiding_countdown);
                }, 1000); // Update jede Sekunde
            },
            async blobToString(blob) {
                return new Promise((resolve, reject) => {
                    const reader = new FileReader();
                    reader.onload = () => {
                        resolve(reader.result);
                    };
                    reader.onerror = (error) => {
                        reject(error);
                    };
                    reader.readAsText(blob);
                });
            },
            async download_blob(fileInfo) {

                const url = "files/generate-url";
                const data = { id: fileInfo.id, token: fileInfo.token, };
                const response = await globals.make_Xhr_Request("POST", url, data);
                if (response.status === 200) {
                    var result = JSON.parse(response.responseText);

                    const dl_response = await globals.make_Xhr_AbortableRequest("GET", result.payload.url, null, "blob");
                    if (dl_response.status === 200) {
                        var blob = dl_response.response;
                        if (fileInfo.is_encrypted) {
                            var edbk = security.encrypted_database_keys.find(d => d.database_key_id == fileInfo.database_key_id);
                            const dbkey = await security.process_decryption_as_crypto_key(edbk.encrypted_database_key, edbk.database_key_iv, security.current_share_key)
                            blob = await security.process_decryption_file_blob(blob, fileInfo, dbkey)
                        }
                        return blob;
                    }
                } else if (response.status === 408) {
                    globals.show_error('errors.files_expired');
                } else if (response.status === 451) {
                    globals.show_error('errors.generateUrl_451_secstring');
                } else if (response.status === 400) {
                    globals.show_error('errors.invalid_link');
                } else if (response.status === 404) {
                    globals.show_error('errors.files_not_found');
                } else {
                    globals.handle_Xhr_Errors(response.status, response.responseText);
                }
            },
            adjustFontSize() {

                this.$nextTick(() => {
                    console.log(this.$refs.secure_string);
                    const container = this.$refs.secure_string[0];
                    container.style.textAlign = `center`;
                    let currentFontSize = this.defaultFontSize;
                    container.style.fontSize = `${currentFontSize}px`
                    while (container.clientHeight > 60 && currentFontSize > this.minFontSize) {
                        currentFontSize--;
                        container.style.fontSize = `${currentFontSize}px`;
                    }
                    if (container.clientHeight > 60) {
                        container.style.textAlign = `left`;
                    }                    
                });
            },
            copyToClipboard(contentToCopy) {
                helper.copyToClipboard(contentToCopy, (newState) => {
                    this.copyToClipBoardButton_state = newState;
                });
            },
        },
        mounted: async function () {
            await this.save_download_content(this.fileShareInfos);
            
        },
    }
</script>
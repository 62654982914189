<template>
    <textarea class="textarea-collapsed"
              :class="{ 'textarea-expanded': focused || content }"
              
              @blur="focused = content.length > 0"
              @input="handleInput"
              v-model="content"></textarea>
</template>

<script>
    export default {
        props: {
            initValue: '',
        },
        data() {
            return {
                content: '',
                focused: false,
            };
        },
        watch: {
            initValue(newValue) {
                this.content = this.initValue;
            },
        },
        methods: {
            handleInput(event) {
                //console.log(event.target.scrollHeight);
                this.content = event.target.value;
                // Optional: Passen Sie die H�he dynamisch an
                /*if (this.content) {*/
                    event.target.style.height = 'auto';
                    event.target.style.height = event.target.scrollHeight + 'px';
                //} else {
                //    event.target.style.height = '54px';
                //}
                this.$emit('update', this.content); // Ausl�sen des benutzerdefinierten Ereignisses
            },
        },
    };
</script>

<style>
    .textarea-collapsed {
        height: 20px;
        overflow: hidden;
        transition: height 0.2s;
    }

    .textarea-expanded {
        height: auto;
        max-height: 78px;
        overflow: auto;
    }
</style>

<template>
    <div id="file-share-view" v-if="is_file_share_view_visible" class="fadeIn">
        <!--<div class="site-content button-right">
            <div class="close-button-bar">
                <div class="button square toolbar" @click="close_file_share_view()"><i class="fa-solid fa-xmark"></i></div>
            </div>
        </div>-->
        <div class="site-title">{{ current_file_share_title }}</div>
        <div class="site-content">
            <FileShare :fileShareInfos="this.current_file_share"></FileShare>
        </div>
        <div class="site-content">
            <div class="button" @click="close_file_share_view()">{{$t('general.buttons.close')}}</div>            
        </div>
    </div>
</template>
<style>
</style>

<script>
    import FileShare from '../components/FileShare.vue'
    import CustomDropdown from '../components/CustomDropdown.vue';

    import helper from '../utils/Helper';
    import globals from '../utils/Globals';
    import security from '../utils/Security';
 
    export default {
        name: 'History',
        setup() {
            return {
                helper, globals, security
            };
        },
        components: {
            FileShare,
            CustomDropdown,
        },
        props: {
            file_share_id: '',
        },
        data: () => ({
            current_download: '',
            current_file_share: '',
            current_file_share_title: '',

            is_main_view_visible: true,
            is_set_new_password_dialog_visible: 0,
            is_success_set_new_password_dialog_visible: 0,
            is_set_new_expiry_date_dialog_visible: false,
            is_success_set_new_expiry_date_dialog_visible: false,
            is_file_share_view_visible: false,            

            confirm_new_password: '',
            new_password: '',

            new_expiry_date: '',
            old_expiry_date: '',

            flatpickrOb: null,

            history_items: [],
            sortOptions: [],
            sortBy: "",
            sortDirection:"",
            isOpen: {},

            
            
        }),
        computed: {           
            
        },
        methods: {
            
            async get_download_history_item() {
                const url = "download/history/" + globals.service + "/" + this.file_share_id
                try {
                    const response = await this.globals.make_Xhr_Request("GET", url);
                    if (response.status === 200) {
                        let result = JSON.parse(response.responseText);
                        // Externe Schleife für download_units
                        this.current_file_share = result.payload.download_units[0];
                    } else {
                        globals.handle_Xhr_Errors(response.status, response.responseText);
                    }
                } catch (e) {
                    console.error("get_download_history failed", e);
                }
                finally {
                    globals.hide_loading();
                }
            },
            close_file_share_view(event) {
                this.$router.push({name: 'History' });
            },
            handleKeyDown(event) {
                if (event.key === 'Escape') {
                    
                }
            },
            async show_me() {
                globals.setPageTitel("history.headline");
                if (this.file_share_id) {
                    await this.get_download_history_item();
                    if (this.current_file_share) {
                        if (this.current_file_share.subject_text) {
                            this.current_file_share_title = this.$i18next.t('history.file_share_details.headline_subject', { subject: this.current_file_share.subject_text });
                        } else {
                            this.current_file_share_title = this.$i18next.t('history.file_share_details.headline_nosubject', { created_date: helper.formatReadableDate(this.current_file_share.created_on, globals.selected_language) });
                        }
                        globals.setPageTitel(this.current_file_share_title);
                        this.is_file_share_view_visible = true;
                        return;
                    }
                } 
                this.close_file_share_view();
            }
        },
        watch: {
            'globals.loggedIn': {
                handler: async function () {
                    await this.show_me();
                }
            }
        },
        mounted: async function () {
            await this.show_me();
            document.addEventListener('keydown', this.handleKeyDown);
        },
        beforeDestroy() {
            document.removeEventListener('keydown', this.handleKeyDown);
        }, 
        
    }
</script>
<template>
    <MarketingPanel class="fadeIn"></MarketingPanel>
    <div id="secure_string_upload">

        <div id="upload-form" class="fadeIn">
            <div class="site-title">{{ $t('secure_string.upload.headline')}}</div>
            <div class="site-content">{{ $t('secure_string.upload.infotext')}}</div>
            <form v-on:submit.prevent="init_upload" class="bodyform" autocomplete="off">
                <div class="bodyform__item">
                    <CustomTextarea :initValue="the_string" @update="handleCustomTextareaUpdate(0, $event)"></CustomTextarea>
                </div>
                <!--<div class="bodyform__item button-right">
                    <button type="button" @click="generate_password()" class="button wide small toolbar">{{ $t('secure_string.upload.form.buttons.generate_pw')}}</button>
                </div>-->
                <div class="bodyform__item center-button">
                    <div>
                        <button class="button wide" type="button" @click="show_generate_link_panel();" :disabled="!the_string">
                            {{ $t('secure_string.upload.form.buttons.upload_link')}}
                        </button>
                    </div>
                </div>
                <div class="bodyform__item center-button">
                    <div>
                        <button class="button wide" type="button" @click="show_generate_email_panel()" :disabled="!the_string">
                            {{ $t('secure_string.upload.form.buttons.upload_mail')}}
                        </button>
                    </div>
                </div>
            </form>
        </div>
    </div>
    <div id="upload-successfully" v-if="is_upload_successfully_view_visible" class="dialog-modal">
        <div class="dialog-container">
            <div class="page_dialog fadeIn less-wide">
                <div class="close-button-bar">
                    <div class="button square toolbar" @click="close_all_upload_dialogs()"><i class="fa-solid fa-xmark"></i></div>
                </div>
                <div v-if="share_url" style="position:relative;">
                    <div class="site-title less-wide">{{ $t('secure_string.upload.dialogs.success_link.headline')}}</div>
                    <div class="site-content less-wide">
                        {{ $t('secure_string.upload.dialogs.success_link.infotext')}}
                    </div>
                    <div class="site-content less-wide">
                        <div class="component-view-list">
                            <div class="component-view-list-body">
                                <div class="component-view-list-body__item framed">
                                    <div class="component-view-list-body__item-content">
                                        <div v-if="!is_the_qr_code_visible" class="component-view-list-body__row">
                                            <div class="share-url">
                                                <a ref="share_url" :href="share_url">{{ share_url }}</a>
                                            </div>
                                        </div>
                                        <div v-if="is_the_qr_code_visible" class="component-view-list-body__row">
                                            <div class="share-url qrcode">
                                                <a :href="share_url">
                                                    <qrcode :value="share_url" :color="{ dark: '#000000ff', light: '#ffffffff' }" errorCorrectionLevel="Q" :width="200" type="image/png"></qrcode>
                                                </a>
                                            </div>
                                        </div>
                                        <div class="component-view-list-body__row button-bar">
                                            <button v-if="!is_the_qr_code_visible && copyToClipBoardButton_state == 0" type="button" @click="copyToClipboard();" class="button square toolbar"><i class="fa-solid fa-copy"></i></button>
                                            <button v-if="!is_the_qr_code_visible && copyToClipBoardButton_state == 1" type="button" @click="copyToClipboard();" class="button square toolbar"><i class="fa-solid fa-check"></i></button>
                                            <button v-if="!is_the_qr_code_visible && copyToClipBoardButton_state == -1" type="button" class="button square toolbar"><i class="fa-solid fa-square-exclamation"></i></button>
                                            <button v-if="!is_the_qr_code_visible" type="button" class="button square toolbar" @click="show_qrcode();"><i class="fa-solid fa-qrcode"></i></button>
                                            <button v-if="is_the_qr_code_visible" type="button" class="button square toolbar" @click="hide_qrcode();"><i class="fa-solid fa-link"></i></button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div v-else>
                    <div class="site-title less-wide">{{ $t('secure_string.upload.dialogs.success_email.headline')}}</div>
                    <div class="site-content less-wide">
                        <div class="content">{{ $t('secure_string.upload.dialogs.success_email.infotext')}}</div>
                    </div>
                </div>
                <div class="site-content less-wide button-right">
                    <button type="button" class="button" @click="close_all_upload_dialogs();">{{ $t('general.buttons.close')}}</button>
                </div>
            </div>
        </div>
    </div>
    <div id="generate-link-form" v-if="is_generate_link_view_visible" class="dialog-modal">
        <div class="dialog-container">
            <div class="page_dialog fadeIn less-wide">
                <div class="close-button-bar">
                    <div class="button square toolbar" @click="show_main_view()"><i class="fa-solid fa-xmark"></i></div>
                </div>
                <div class="site-title less-wide">{{ $t('secure_string.upload.headline_link')}}</div>
                <div class="dialog-content">
                    <div class="site-content less-wide">
                        <div class="content">
                            {{ $t('secure_string.upload.infotext_link')}}
                        </div>
                    </div>
                    <form v-on:submit.prevent="init_upload" class="bodyform less-wide" autocomplete="off">
                        <div class="bodyform__item">
                            <label for="upload_password">{{ $t('secure_string.upload.form.labels.password')}}</label>
                            <input id="upload_password" name="upload_password" @focus="handleUploadPasswordFocus($event);" @blur="handleUploadPasswordFocus($event);" type="password" v-model="upload_password" autocomplete="new-password" />
                        </div>
                        <div v-if="is_confirm_passwort_input_visbible" class="bodyform__item">
                            <label for="upload_password_confirm">{{ $t('secure_string.upload.form.labels.password_confirm')}}</label>
                            <input id="upload_password_confirm" name="upload_password_confirm" type="password" v-model="upload_password_confirm" autocomplete="new-password" />
                        </div>
                        <div class="bodyform__item">
                            <label for="expiry_date">{{ $t('secure_string.upload.form.labels.expiry_date')}}</label>
                            <input id="expiry_date" ref="expiry_date" name="expiry_date" type="datetime" v-model="expiry_date" />
                        </div>
                        <div class="bodyform__item two_buttons more_space">
                            <button class="button toolbar" type="button" @click="show_main_view()">{{ $t('general.buttons.cancel')}}</button>
                            <button class="button" type="submit" :disabled="!is_tos_pp_checked">{{ $t('general.buttons.create')}}</button>
                        </div>
                    </form>
                    <div class="site-content less-wide">
                        <div class="content" v-html="$t('general.tos_and_pp_checkbox.infotext')"></div>
                        <div class="content">
                            <label class="custom-checkbox-container">
                                <input type="checkbox" @change="check_tos_pp($event)" name="tos_checkbox" id="tos_checkbox">
                                <span class="checkmark" style="left : 0;"></span>
                                <label for="tos_checkbox" v-html="$t('general.tos_and_pp_checkbox.checkbox')"></label>
                            </label>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div id="generate-email-form" v-if="is_generate_email_view_visible" class="dialog-modal">
        <div class="dialog-container">
            <div class="page_dialog fadeIn less-wide">
                <div class="close-button-bar">
                    <!--<div style="width: 38px; height: 38px; display: flex; flex-direction: column; background-color: antiquewhite; justify-content: center; text-align: center; "><i class="fa-solid fa-xmark"></i></div>-->
                    <div class="button square toolbar" @click="show_main_view()"><i class="fa-solid fa-xmark"></i></div>
                </div>
                <div class="site-title less-wide">{{ $t('secure_string.upload.headline_email')}}</div>
                <div class="dialog-content">
                    <div class="site-content less-wide">
                        <div class="content">
                            {{ $t('secure_string.upload.infotext_email')}}
                        </div>
                    </div>
                    <form v-on:submit.prevent="init_upload" class="bodyform less-wide" autocomplete="off">
                        <div class="bodyform__item">
                            <label for="toaddy">{{ $t('secure_string.upload.form.labels.to_addy')}}</label>
                            <input type="email" v-model="toaddy" />
                        </div>
                        <div v-if="globals.loggedIn" class="bodyform__item">
                            <label for="upload_password">{{ $t('secure_string.upload.form.labels.password')}}</label>
                            <input id="upload_password" name="upload_password" type="password" @focus="handleUploadPasswordFocus($event);" @blur="handleUploadPasswordFocus($event);" v-model="upload_password" autocomplete="new-password" />
                        </div>

                        <div v-if="globals.loggedIn && is_confirm_passwort_input_visbible" class="bodyform__item">
                            <label for="upload_password_confirm">{{ $t('secure_string.upload.form.labels.password_confirm')}}</label>
                            <input id="upload_password_confirm" name="upload_password_confirm" type="password" v-model="upload_password_confirm" autocomplete="new-password"  />
                        </div>

                        <div v-if="globals.loggedIn" class="bodyform__item">
                            <label for="expiry_date">{{ $t('secure_string.upload.form.labels.expiry_date')}}</label>
                            <input id="expiry_date" ref="expiry_date" name="expiry_date" type="datetime" v-model="expiry_date"  />
                        </div>

                        <div class="bodyform__item two_buttons more_space">
                            <button class="button toolbar" type="button" @click="show_main_view()">{{ $t('general.buttons.cancel')}}</button>
                            <button class="button" type="submit" :disabled="!is_tos_pp_checked">{{ $t('secure_string.upload.form.buttons.submit')}}</button>
                        </div>
                    </form>
                    <div class="site-content less-wide">
                        <div class="content" v-html="$t('general.tos_and_pp_checkbox.infotext')"></div>
                        <div class="content">
                            <label class="custom-checkbox-container">
                                <input type="checkbox" @change="check_tos_pp($event)" name="tos_checkbox" id="tos_checkbox">
                                <span class="checkmark" style="left : 0;"></span>
                                <label for="tos_checkbox" v-html="$t('general.tos_and_pp_checkbox.checkbox')"></label>
                            </label>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
    import Qrcode from 'vue-qrcode';

    import MarketingPanel from '../components/MarketingPanel.vue';
    import CustomTextarea from '../components/CustomTextarea.vue';

    import helper from '../utils/Helper';
    import globals from '../utils/Globals';
    import security from '../utils/Security';
    import validator from 'validator';

    export default {
        name: 'Upload',
        setup() {
            return {
                helper, globals, security
            };
        },
        components: {
            Qrcode,
            MarketingPanel,
            CustomTextarea
        },
        props: {
            msg: String
        },
        data: () => ({
            the_string: '',
            toaddy: '',
            upload_password: '',
            upload_password_confirm: '',
            expiry_date: '',
            upload_token: '',
            share_token: '',
            share_url: '',            
            dateBox: '',
            flatpickrOb: null,

            copyToClipBoardButton_state: 0,

            is_the_qr_code_visible: false,


            is_generate_link_view_visible: false,
            is_generate_email_view_visible: false,
            is_upload_successfully_view_visible: false,
            is_confirm_passwort_input_visbible: false,
            is_tos_pp_checked: false,

        }),
        computed: {
            
        },
        methods: {
            async init_upload() {
                if (globals.loggedIn && (this.upload_password + "") !== (this.upload_password_confirm + "")) {
                    globals.show_error('errors.password_not_match');
                    return;
                }
                
                let to_addresses = [];
                if (this.is_generate_email_view_visible) {
                    let errors = [];
                    errors.push(this.$i18next.t('upload.form.errors.intro'))

                    if (this.toaddy && this.toaddy.trim().length > 0) {
                        to_addresses.push(this.toaddy);
                    }
                    if (to_addresses.length > 0) {
                        for (var i = 0; i < to_addresses.length; i++) {
                            let tempAddy = to_addresses[i].trim();

                            if (!validator.isEmail(tempAddy)) {
                                errors.push("- " + this.$i18next.t('upload.form.errors.valid_to', { emailaddy: tempAddy }));
                            }
                        }
                    } else {
                        errors.push("- " + this.$i18next.t('upload.form.errors.requiered_to'));
                    }                    
                    if (errors.length > 1) {
                        globals.errors = errors;
                        return;
                    }
                }

                const url = "upload/init";
                const initRequestData = {
                    service: globals.service,
                    password: this.upload_password,
                    expiry_date: this.expiry_date !== '' ? this.flatpickrOb.selectedDates[0] : null,
                    upload_type: "secure_string",
                    to_addresses: to_addresses,
                };

                // Leere Felder aus dem JSON-Objekt entfernen
                const filteredData = {};
                for (const key in initRequestData) {
                    if (initRequestData[key] !== '') {
                        filteredData[key] = initRequestData[key];
                    }
                }

                try {
                    const response = await this.globals.make_Xhr_Request("POST", url, filteredData);
                    if (response.status === 200) {
                        var result = JSON.parse(response.responseText);
                        this.upload_token = result.payload.upload_token;
                        this.confirmation_code_token = result.payload.confirmation_code_token;
                        if (this.upload_token) {
                            await this.start_upload(result.payload.share_tokens);
                        } else {
                            globals.show_error('errors.not_ok_status');
                        }
                    } else if (response.status === 400) {
                        globals.errors.push("Daten zum Versand unvollständig. ");
                        var splitz = response.responseText.split(/[;]+/);
                        for (var i = 0; i < splitz.length; i++) {
                            globals.errors.push("- " + splitz[i]);
                        }
                    } else {
                        globals.handle_Xhr_Errors(response.status, response.responseText);
                    }
                } catch (e) {
                    console.error("init_upload failed", e);
                }
                finally {
                    this.globals.hide_loading();
                }
            },
            async start_upload(share_tokens) {

                const url = "upload/encrypted-files/" + this.upload_token;
                let formData = new FormData();
                // Alle ausgewählten Dateien zum FormData-Objekt hinzufügen
                let share_token_encryption_infos = [];
                for (const share_token of share_tokens) {

                    const pw = security.generate_pw(); //für Server
                    const salt = security.generate_salt(); //für Server
                    const personal_key = await security.generate_personal_key(pw, salt); //nur Temp 
                    const edki = await security.generate_encrypted_database_key_info(personal_key);
                    //console.log("edki.encryptedOutput", edki.encryptedOutput);
                    //console.log("edki.iv", edki.iv);
                    //console.log("personal_key", personal_key);

                    const dbKey = await security.process_decryption_as_crypto_key(edki.encryptedOutput, edki.iv, personal_key);
                    share_token_encryption_infos.push(
                        {
                            id: share_token, //für Server - DB
                            pw: pw, //für Server - Mail
                            salt: salt, //für Server - DB
                            encrpyt_dbKey: edki.encryptedOutput,//für Server - DB
                            encrpyt_dbKey_iv: edki.iv,//für Server -DB
                            dbKey: dbKey, //nur Temporär
                        }
                    );
                }
                //console.log("bin hier###1");
                //console.log("bin hier###1a", security.encrypted_database_key);
                //console.log("bin hier###1b", security.database_key_iv);
                //console.log("bin hier###1c", security.personal_key);
                const dbkey = await security.process_decryption_as_crypto_key(security.encrypted_database_key, security.database_key_iv, security.personal_key)
                //console.log("bin hier###2");
                const file = new File([this.the_string], "filename.securestring", {
                    type: "text/plain",
                });
                let selectedFiles = [];
                selectedFiles.push(file);

                for (let i = 0; i < selectedFiles.length; i++) {
                    const file = selectedFiles[i];
                    const encrypted_data = await security.process_encryptionFile(file, dbkey, share_token_encryption_infos);
                    formData.append('file[' + i + ']', encrypted_data.encryptedFileData);
                    formData.append('fileKey[' + i + ']', encrypted_data.encryptedFileKey);
                    formData.append('fileDbKeyId[' + i + ']', security.database_key_id);
                    formData.append('fileIV[' + i + ']', encrypted_data.fileIV);
                    formData.append('fileInfo[' + i + ']', encrypted_data.encryptedFileInfos);
                    formData.append('fileShares[' + i + ']', JSON.stringify(encrypted_data.share_token_file_encryption_infos));
                }

                try {
                    const response = await globals.make_Xhr_AbortableRequest("POST", url, formData);
                    //console.log("response", response);
                    if (response.status === 200) {
                        var result = JSON.parse(response.responseText);
                        this.share_token = result.payload.share_token;
                        this.share_url = result.payload.share_url;
                        this.is_upload_successfully_view_visible = true;
                        this.is_generate_link_view_visible = false;
                        this.is_generate_email_view_visible = false;
                        this.reset_upload_dialog_inputs();

                    } else if (response.status === 403) {
                        globals.show_error('errors.upload_rejected');
                    } else if (response.status === 401) {
                        globals.show_error('errors.upload_rejected');
                    } else if (response.status === 0) {
                        //abgebrochen
                    } else {
                        globals.handle_Xhr_Errors(response.status, response.responseText);
                    }
                } catch (e) {
                    console.error("start_upload failed", e);
                }
                finally {
                    globals.hide_loading();
                }
            },
            generate_password() {
                this.the_string = security.generate_pw();

            },
            show_main_view() {
                this.is_generate_link_view_visible = false;
                this.is_generate_email_view_visible = false;
                this.is_tos_pp_checked = false;
                this.reset_upload_dialog_inputs();
            },
            show_generate_link_panel() {
                this.is_generate_link_view_visible = true;
            },
            show_generate_email_panel() {
                this.is_generate_email_view_visible = true;
            },
            close_all_upload_dialogs() {
                this.is_generate_link_view_visible = false;
                this.is_generate_email_view_visible = false;
                this.is_upload_successfully_view_visible = false;
                this.reset_upload_dialog_inputs();
            },
            reset_upload_panel() {
                this.selectedFiles = [];
                this.reset_upload_dialog_inputs();
                this.is_upload_successfully_view_visible = false;
                this.is_generate_link_view_visible = false;
                this.is_generate_email_view_visible = false;

                this.upload_token = '';
                this.confirmation_code_token = '';
                this.confirmation_code = '';
                this.share_token = '';
                this.share_url = '';
            },
            reset_upload_dialog_inputs() {
                this.toaddy = '';
                this.upload_password = '';
                this.upload_password_confirm = '';
                this.expiry_date = '';
            },
            initializeFlatpickr(dateBox) {
                const locale = globals.selected_language === 'de' ? flatpickr.l10ns.de : flatpickr.l10ns.default;
                const dateFormat = globals.selected_language === 'de' ? 'd. M Y H:i' : 'M/d/Y h:i K';

                this.flatpickrOb = flatpickr(dateBox, {
                    enableTime: true, 
                    minDate: "today", 
                    maxDate: new Date().fp_incr(7), 
                    locale: locale,
                    dateFormat: dateFormat,
                });
            },
            check_tos_pp(event) {
                const target = event.target;
                this.is_tos_pp_checked = target.checked;
            },
            copyToClipboard() {
                helper.copyToClipboard(this.share_url, (newState) => {
                    this.copyToClipBoardButton_state = newState;
                });
            },
            show_qrcode() {
                this.is_the_qr_code_visible = true;
            },
            hide_qrcode() {
                this.is_the_qr_code_visible = false;
            },
            handleCustomTextareaUpdate(index, content) {
                if (index === 0) {
                    console.log(content);
                    this.the_string = content
                //} else if (index === 1) {
                //    console.log(content);
                //    this.toaddys = content
                }
                
            },
            handleUploadPasswordFocus(event) {
                console.log("event:", event);
                if (event.type === 'focus') {
                    this.is_confirm_passwort_input_visbible = true;
                }
                if (event.type === 'blur') {
                    if (this.upload_password.length <= 0) {
                        this.is_confirm_passwort_input_visbible = false;
                        this.upload_password_confirm = '';
                    }
                }
            },
            handleKeyDown(event) {
                if (event.key === 'Escape') {
                    if (this.is_upload_successfully_view_visible)
                        this.reset_upload_panel();
                    if (this.is_generate_email_view_visible)
                        this.close_all_upload_dialogs();
                    if (this.is_generate_link_view_visible)
                        this.close_all_upload_dialogs();
                }
            }
        },
        watch: {
            'globals.selected_language': {
                handler: function (newVal, oldVal) {
                    if (this.flatpickrOb !== null) {
                        this.flatpickrOb.destroy();
                        this.initializeFlatpickr(this.dateBox);
                    }
                },
                immediate: true
            },
            'selectedFileTotalSize': {
                handler: function () {
                    const maxSize = globals.loggedIn ? 2147483648 : 209715200;
                    if (this.selectedFileTotalSize > maxSize) {  //2GB
                        this.selectedFileTotalSize_tooLarge = true;
                    } else {
                        this.selectedFileTotalSize_tooLarge = false;
                    }

                }
            }
        },
        mounted() {
            document.addEventListener('keydown', this.handleKeyDown);
            globals.setPageTitel("secure_string.upload.headline");
            this.max_toaddys_length = globals.loggedIn ? 5 : 2;

        },
        beforeDestroy() {
            document.removeEventListener('keydown', this.handleKeyDown);
            this.flatpickrOb.destroy();
            this.flatpickrOb = null;

        },
        updated() {
            if (this.$refs.expiry_date && !this.flatpickrOb) {
                this.dateBox = this.$refs.expiry_date;
                this.initializeFlatpickr(this.dateBox);
            }
            if (!this.$refs.expiry_date) {
                this.flatpickrOb = null;
            }
            this.max_toaddys_length = globals.loggedIn ? 5 : 2;
        }
    }
</script>
<template>
    <div>
        <FileShareItem :folder="folder"></FileShareItem>
    </div>
</template>

<script>
    import FileShareItem from './FileShareItem.vue';

    export default {
        name: "FileShareExplorer",
        components: { FileShareItem },
        props: {
            filesTree: Array
        },
        data() {
            return {
                folder: {},
            };
        },
        methods: {
        },
        mounted: function () {
            if (this.filesTree) {
                this.folder = this.filesTree;
            }
        },
        watch: {
            filesTree: {
                handler: function (newFiles, oldFiles) {
                    this.folder = newFiles;
                },
                deep: true
            }
        }
    }
</script>

<style scoped>
    li {
        cursor: pointer;
        margin: 5px 0;
    }

    ul {
        list-style-type: none;
        padding-left: 20px;
    }
</style>
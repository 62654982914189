import { reactive } from 'vue';
import { v4 as uuidv4 } from 'uuid';
const helper = reactive({
    formatFileSize(sizeInBytes, currentLanguage) {
        if (sizeInBytes === 0) {
            return '0 Bytes';
        }

        const k = 1024;
        const sizes = ['Bytes', 'KB', 'MB', 'GB'];
        const i = Math.floor(Math.log(sizeInBytes) / Math.log(k));


        // Erstellt ein NumberFormat-Objekt mit der �bergebenen Sprache
        const numberFormatter = new Intl.NumberFormat(currentLanguage);
        // Verwendet das NumberFormat-Objekt, um die Zahl zu formatieren
        const formattedSize = numberFormatter.format((sizeInBytes / Math.pow(k, i)).toFixed(2));

        let stringFileSize = formattedSize + ' ' + sizes[i];
        return stringFileSize;


        //let stringFileSize = parseFloat((sizeInBytes / Math.pow(k, i)).toFixed(2)) + ' ' + sizes[i];;
        //return stringFileSize;
    },
    formatReadableDate(dateString, currentLanguage, option) {
        const date = new Date(dateString);
        if (!option) {
            option = { weekday: 'long', month: 'long' };
        } else {
            if (!option.weekday) {
                option.weekday = 'long';
            }
            if (!option.month) {
                option.month = 'long';
            }
        }
        if (currentLanguage && currentLanguage.startsWith('de')) {
            let options = { weekday: option.weekday, year: 'numeric', month: option.month, day: '2-digit', hour: 'numeric', minute: 'numeric', hour12: false };
            let formattedDate = date.toLocaleDateString(currentLanguage, options);
            return `${formattedDate} Uhr`;
        } else {
            // F�r andere Lokalisierungen wird die Standardformatierung ohne "Uhr" verwendet
            let options = { weekday: option.weekday, year: 'numeric', month: option.month, day: '2-digit', hour: 'numeric', minute: 'numeric', hour12: true };
            let formattedDate = date.toLocaleDateString(currentLanguage, options);
            return `${formattedDate}`;
        }
    },
    validateEmail(inputString) {
        const input = document.createElement('input');
        input.type = 'email';
        input.value = inputString;
        // �berpr�ft, ob die E-Mail-Adresse g�ltig ist
        return input.checkValidity();
    },
    getJsonFromBase64(base64String) {
        const decodedString = atob(base64String.replace(/-/g, ''));
        return JSON.parse(decodedString);
    },
    copyToClipboard(contentToCopy, updateStateCallback) {
        if (navigator.clipboard && window.isSecureContext) {
            this.copyToClipboardNew(contentToCopy, updateStateCallback);
        } else {
            this.copyToClipboardOld(contentToCopy, updateStateCallback);
        }
    },
    copyToClipboardNew(contentToCopy, updateStateCallback) {
        navigator.clipboard.writeText(contentToCopy)
            .then(() => {
                updateStateCallback(1); // Erfolg
                setTimeout(() => updateStateCallback(0), 500);
            })
            .catch(err => {
                console.error("Fehler beim Kopieren", err);
                updateStateCallback(-1); // Fehler
                setTimeout(() => updateStateCallback(0), 1000);
            });
    },
    copyToClipboardOld(contentToCopy, updateStateCallback) {
        const textarea = document.createElement('textarea');
        textarea.value = contentToCopy;
        document.body.appendChild(textarea);
        textarea.select();
        textarea.setSelectionRange(0, 99999);
        try {
            document.execCommand('copy');
            updateStateCallback(1); // Erfolg
            setTimeout(() => updateStateCallback(0), 500);
        } catch (err) {
            console.error("Fehler beim Kopieren", err);
            updateStateCallback(-1); // Fehler
            setTimeout(() => updateStateCallback(0), 1000);
        }
        document.body.removeChild(textarea);
    },
    getFileIconByContentType(contentType) {

        if (!contentType) return 'fa-file'; // Wenn contentType nicht definiert ist, geben Sie ein allgemeines Datei-Icon zur�ck

        switch (contentType.split(';')[0]) { // Entfernt etwaige zus�tzliche Parameter (wie charset) vom MIME-Type
            // Bilder
            case 'image/jpeg':
            case 'image/png':
            case 'image/gif':
            case 'image/bmp':
            case 'image/tiff':
            case 'image/webp':
            case 'image/svg+xml':
                return 'fa-file-image';

            case 'application/postscript':
                return 'fa-file-eps';

            // Videos
            case 'video/mp4':
            case 'video/x-matroska':
            case 'video/x-flv':
            case 'video/webm':
            case 'video/x-msvideo':
            case 'video/quicktime':
                return 'fa-file-video';

            // Audios
            case 'audio/mpeg':
            case 'audio/wav':
            case 'audio/ogg':
            case 'audio/x-m4a':
                return 'fa-file-audio';

            // Dokumente
            case 'application/pdf':
                return 'fa-file-pdf';
            case 'application/msword':
            case 'application/vnd.openxmlformats-officedocument.wordprocessingml.document':
                return 'fa-file-word';
            case 'application/vnd.ms-powerpoint':
                return 'fa-file-powerpoint';
            case 'application/vnd.ms-excel':
            case 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet':
                return 'fa-file-excel';
            case 'text/csv':
                return 'fa-file-csv';
            case 'application/vnd.ms-powerpoint':
            case 'application/vnd.openxmlformats-officedocument.presentationml.presentation':
                return 'fa-file-powerpoint';
            case 'text/plain':
                return 'fa-file-lines';

            // Code & Scripts
            case 'application/javascript':
            case 'application/x-javascript':
                return 'fa-file-code';
            case 'text/html':
                return 'fa-file-code';
            case 'text/css':
                return 'fa-file-code';
            case 'application/json':
            case 'application/xml':
                return 'fa-file-code';

            // Archive
            case 'application/zip':
            case 'application/x-rar-compressed':
            case 'application/x-7z-compressed':
            case 'application/x-tar':
                return 'fa-file-zipper';

            // Bin�r
            case 'application/octet-stream':
                return 'fa-file-binary';

            // Andere
            default:
                return 'fa-file'; // Ein allgemeines Datei-Icon f�r nicht spezifizierte Content-Types
        }
    },
    getFileIconByExtension(extension) {
        switch (extension.toLowerCase()) {
            // Bilder
            case '.jpg':
            case '.jpeg':
            case '.png':
            case '.gif':
            case '.bmp':
            case '.tiff':
            case '.webp':
            case '.svg':
                return 'fa-file-image';

            //// svg-Bilder
            //case '.svg':
            //    return 'fa-file-svg';

            // Videos
            case '.mp4':
            case '.mkv':
            case '.flv':
            case '.webm':
            case '.avi':
            case '.mov':
                return 'fa-file-video';

            // Audios
            case '.mp3':
            case '.wav':
            case '.ogg':
            case '.m4a':
                return 'fa-file-audio';

            // Dokumente
            case '.pdf':
                return 'fa-file-pdf';
            case '.doc':
            case '.docx':
                return 'fa-file-word';
            case '.xls':
            case '.xlsx':
                return 'fa-file-excel';
            case '.ppt':
            case '.pptx':
                return 'fa-file-powerpoint';
            case '.txt':
            case '.md':
                return 'fa-file-lines';

            // Code & Scripts
            case '.js':
            case '.jsx':
            case '.ts':
                return 'fa-file-code';
            case '.html':
            case '.htm':
                return 'fa-file-code';
            case '.css':
            case '.scss':
            case '.sass':
                return 'fa-file-code';
            case '.json':
            case '.xml':
                return 'fa-file-code';

            // Archive
            case '.zip':
            case '.rar':
            case '.7z':
            case '.tar':
            case '.gz':
                return 'fa-file-archive';

            // Archive
            case '.csv':
                return 'fa-file-csv';
            // Archive
            case '.dat':
            case '.exe':
            case '.com':
            case '.msi':
                return 'fa-file-binary';

            // Andere
            default:
                return 'fa-file'; // Ein allgemeines Datei-Icon f�r nicht spezifizierte Erweiterungen
        }
    },
    getCurrentDateFormatted() {
        const now = new Date();

        const year = now.getFullYear();
        const month = (now.getMonth() + 1).toString().padStart(2, '0'); // Monate sind 0-basiert in JS, daher +1; padStart f�gt f�hrende Nullen hinzu
        const day = now.getDate().toString().padStart(2, '0');

        return `${year}${month}${day}`;
    },

    buildFileTreeFromFlatFileArray(flatArray, sortOptions, limits) {
        //console.log("buildTree #3", flatArray);

        // Standardm��ige Sortieroptionen
        const defaultLimits = {
            maxFileSize: '',
        };

        // Standardm��ige Sortieroptionen
        const defaultSortOptions = {
            sortName: 'name', // 'size' oder 'name'
            sortOrder: 'ASC'  // 'ASC' oder 'DESC'
        };

        // Aktuelle Sortieroptionen aus den �bergebenen Optionen oder den Standardwerten
        const { sortName, sortOrder } = { ...defaultSortOptions, ...sortOptions };

        // Aktuelle Limits aus den �bergebenen Limits oder den Standardwerten
        const { maxFileSize } = { ...defaultLimits, ...limits };

        const root = [];

        flatArray.forEach(file => {
            let visibleOptions = {
                fileSizeExceeded: false,
            };
            if (!file.relativePath) {
                if (maxFileSize) {
                    visibleOptions.fileSizeExceeded = file.size > maxFileSize
                }
                root.push({ id: file.id, name: file.name, itemType: 'file', size: file.size, contentType: file.type, uploadId: file.uploadId, visibleOptions: visibleOptions });
            } else {
                const pathParts = file.relativePath.split('/');
                const relativePathDirOnly = file.relativePath.replace(file.name, "");
                let currentLevel = root;
                pathParts.forEach((part, index) => {
                    if (index === pathParts.length - 1) {
                        if (maxFileSize) {
                            visibleOptions.fileSizeExceeded = file.size > maxFileSize
                        }
                        currentLevel.push({ id: file.id, name: part, itemType: 'file', size: file.size, contentType: file.type, uploadId: file.uploadId, visibleOptions: visibleOptions });
                    } else {
                        let existingFolder = currentLevel.find(item => item.name === part && item.itemType === 'folder' && item.uploadId === file.uploadId);
                        if (!existingFolder) {
                            existingFolder = {
                                id: "folder_" + relativePathDirOnly + "_" + file.uploadId,
                                name: part,
                                itemType: 'folder',
                                children: [],  // Stellen Sie sicher, dass 'children' immer initialisiert wird.
                                uploadId: file.uploadId,
                                size: 0,
                                fileCount: 0,
                                visibleOptions: visibleOptions

                            };
                            currentLevel.push(existingFolder);
                        }
                        currentLevel = existingFolder.children;
                    }
                });
            }
        });

        // Hilfsfunktion zum Sortieren der Elemente
        const sortFunction = (a, b) => {
            if (a.itemType !== b.itemType) {
                return a.itemType === 'folder' ? -1 : 1;
            }

            const fieldA = a[sortName];
            const fieldB = b[sortName];

            let result;
            if (sortName === 'name') {
                result = fieldA.localeCompare(fieldB);
            } else if (sortName === 'size') {
                result = fieldA - fieldB;
            }

            return sortOrder === 'ASC' ? result : -result;
        };

        // Funktion zum Berechnen der Gr��e und Anzahl der Dateien jedes Ordners
        const calculateFolderDetails = (item) => {
            let visibleOptions = {
                fileSizeExceeded: false,
            };    
            if (item.itemType === 'file') {
                // Bei Dateien, gib die Dateigr��e zur�ck
                return { size: item.size, count: 1, visibleOptions: item.visibleOptions };
            } else if (item.itemType === 'folder') {
                // Rekursives Berechnen der Gr��e und Dateianzahl aller Kinder eines Ordners
                let totalSize = 0, totalCount = 0;
                visibleOptions.fileSizeExceeded = false;
                item.children.forEach(child => {
                    let childDetails = calculateFolderDetails(child);
                    totalSize += childDetails.size;
                    totalCount += childDetails.count;
                    if (childDetails.visibleOptions.fileSizeExceeded) {
                        visibleOptions.fileSizeExceeded = true;
                    }
                });
                item.size = totalSize; // Aktualisiere die Gr��e des Ordners
                item.fileCount = totalCount; // Aktualisiere die Anzahl der Dateien im Ordner
                item.visibleOptions = visibleOptions;
                return { size: totalSize, count: totalCount, visibleOptions: visibleOptions }; // Gib die berechneten Details zur�ck
            }
            return { size: 0, count: 0, visibleOptions: visibleOptions }; // Standardr�ckgabe
        };

        // Berechne die Gr��en aller Elemente im Root und deren Kinder
        root.forEach(item => calculateFolderDetails(item));

        // Sortiere das Wurzelelement und alle Unterordner
        const sortElements = elements => {
            elements.sort(sortFunction);
            elements.forEach(element => {
                if (element.itemType === 'folder' && element.children) {
                    sortElements(element.children);
                }
            });
        };
        sortElements(root);
        /*console.log("buildTree #4", root);*/
        return root;
    },
    listAllItemIdsInFolder(id, fileTree, idsSet = new Set()) {
        for (const item of fileTree) {
            //console.log("item", item, item.id === id, item.itemType === 'folder');
            if (item.id === id && item.itemType === 'folder') {
                this.flatArrayToFileList(item.children, idsSet);
                return idsSet;
            } else if (item.itemType === 'folder') {
                this.listAllItemIdsInFolder(id, item.children, idsSet);
            }
        }
        return idsSet; // Falls der Ordner nicht gefunden wird
    },

    flatArrayToFileList(array, idsSet = new Set()) {
        array.forEach(item => {
            if (item.itemType !== 'folder') {
                idsSet.add(item.id);
            }
            if (item.children) {
                this.flatArrayToFileList(item.children, idsSet);
            }
        });
        return idsSet;
    },

    flattenFileTree(data) {
        const result = [];

        function processItems(items, path = '') {
            items.forEach(item => {
                // Aktualisiere den Pfad, wenn das aktuelle Element ein Ordner ist
                const currentPath = path ? `${path}/${item.name}` : item.name;

                if (item.itemType === 'file') {
                    // F�ge die Datei mit dem vollst�ndigen Pfad hinzu
                    result.push({
                        ...item,
                        path: currentPath
                    });
                } else if (item.children && item.children.length > 0) {
                    // Rekursive Suche nach Dateien in den Kindern
                    processItems(item.children, currentPath);
                }
            });
        }

        // Starte die Verarbeitung mit dem �bergebenen Datenarray und einem leeren Pfad
        processItems(data);
        return result;
    },
    ensureUniqueNames(fileTree) {
        function processItems(items) {
            const nameCount = new Map(); // Speichert, wie oft ein Name vorkommt

            items.forEach(item => {
                let baseName = item.name;
                let extension = '';

                // Trenne die Dateierweiterung vom Basisnamen, wenn es eine Datei ist
                if (item.itemType === 'file') {
                    const dotIndex = item.name.lastIndexOf('.');
                    if (dotIndex >= 0) {
                        baseName = item.name.substring(0, dotIndex);
                        extension = item.name.substring(dotIndex); // Einschlie�lich des Punktes
                    }
                }

                let count = nameCount.get(baseName) || 0;
                if (count > 0) {
                    // F�ge das Suffix vor der Erweiterung ein, wenn es eine Datei ist
                    item.name = `${baseName} (${count})${extension}`;
                }
                nameCount.set(baseName, count + 1); // Update the counter for this name base

                // Rekursiver Aufruf f�r Kinder, falls vorhanden
                if (item.children && item.children.length > 0) {
                    processItems(item.children);
                }
            });
        }
        // Starte den Prozess mit dem gegebenen Datenarray
        processItems(fileTree);
    },    
    findCompleteFolders(nodes, selected_fileIds) {
        const selected_folderIds = [];

        function checkComplete(node) {
            if (node.itemType === 'file') {
                return selected_fileIds.includes(node.id);
            } else if (node.itemType === 'folder') {
                let allSelected = true;
                for (const child of node.children) {
                    if (!checkComplete(child)) {
                        allSelected = false;
                        break;
                    }
                }
                if (allSelected) {
                    selected_folderIds.push(node.id);
                }
                return allSelected;
            }
        }
        nodes.forEach(node => checkComplete(node));
        return selected_folderIds;
    }    
});
export default helper;

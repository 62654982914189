<template>
    <div id="opening-offer-div" :class="{'ended': ended}">
        <span v-if="ended">
            {{$t('marketing.opening_offer.promotion_ended')}}
        </span>
        <span v-else>
            {{$t('marketing.opening_offer.remaining_text', {counter: payload.remaining})}}            
        </span>
    </div>
</template>
<script>
    export default {
        name: 'OpeningOffer',
        setup() {
        },
        props: {
            payload:''
        },
        data: () => ({
            ended: ''
            
        }),
        methods: {

        },
        mounted: function () {
            console.log(this.payload.remaining);
            this.ended = this.payload.remaining <= 0;
        },
    }
</script>
import { createRouter, createWebHistory } from 'vue-router';
import FileShareUpload from '@common_views/FileShareUpload.vue';
import FileShareDownload from '@common_views/FileShareDownload.vue';
import Register from '@common_views/Register.vue';
import Profile from '@common_views/Profile.vue';
import FileShareHistory from '@common_views/FileShareHistory.vue';
//import Supplicants from '@common_views/Supplicants.vue';
import Login from '@common_views/Login.vue';
//import Features from '@common_views/Features.vue';
//import Pricing from '@common_views/Pricing.vue';
//import PrivacyPage from '@common_views/PrivacyPage.vue';
import HistoryDetails from '@common_views/HistoryDetails.vue';
import SecureStringUpload from '@common_views/SecureStringUpload.vue';
import SecureStringDownload from '@common_views/SecureStringDownload.vue';

import security from '@common_utils/Security';
import globals from '@common_utils/Globals';


const routes = [
    {
        path: '/',
        name: 'Home',
        component: FileShareUpload
    },
    {
        path: '/upload',
        name: 'Upload',
        component: FileShareUpload
    },
    {
        path: '/register/:register_token?/:register_with_email?',
        name: 'Register',
        component: Register,
        props: true
    },
    {
        path: '/openingoffer/',
        name: 'OpeningOffer',
        component: Register,
        props: { src: 'OpeningOffer', register_token: null, register_with_email: null }
    },
    {
        path: '/confirm-email',
        name: 'Register_confirm_email',
        component: Register,
        props: (route) => ({ token: route.query.token })
    },
    {
        path: '/login',
        name: 'Login',
        component: Login
    },
    {
        path: '/logout',
        name: 'Logout',
        component: Login
    },
    {
        path: '/forgot_pw',
        name: 'Login_forgot_pw',
        component: Login
    },
    {
        path: '/new_pw',
        name: 'Login_new_pw',
        component: Login
    },
    {
        path: '/resend_confirmation',
        name: 'Login_resend_confirmation',
        component: Login
    },
    {
        path: '/confirm-forgot-pw',
        name: 'Login_confirm_forgot_pw',
        component: Login,
        props: (route) => ({ token: route.query.token })
    },
    {
        path: '/confirm-change-email',
        name: 'Login_confirm_change_email',
        component: Login,
        props: (route) => ({ token: route.query.token }),
    },
    {
        path: '/sshare/:token?',
        name: 'SecureShare',
        component: FileShareDownload,
        props: true
    },
    {
        path: '/share/:token?',
        name: 'Share',
        component: FileShareDownload,
        props: true
    },
    {
        path: '/profile',
        name: 'Profile',
        component: Profile,
        meta: { requiresAuth: true }
    },
    {
        path: '/init-change-email',
        name: 'Profile_init_change_email',
        component: Profile,
        props: (route) => ({ token: route.query.token }),
        meta: { requiresAuth: true }
    },
    {
        path: '/history',
        name: 'History',
        component: FileShareHistory,
        meta: { requiresAuth: true }
    },
    {
        path: '/history/details/:file_share_id?',
        name: 'History_Details',
        component: HistoryDetails,
        meta: { requiresAuth: true },
        props: true,
    },
    //{
    //    path: '/systemcheck/:apikey',
    //    name: 'systemcheck',
    //},
    //{
    //    path: '/features',
    //    name: 'Features',
    //    component: Features,
    //},
    //{
    //    path: '/pricing',
    //    name: 'Pricing',
    //    component: Pricing,
    //},
    //{
    //    path: '/privacy',
    //    name: 'PrivacyPage',
    //    component: PrivacyPage,
    //},
    {
        path: '/csecurestring',
        name: 'SecureStringUpload',
        component: SecureStringUpload,
        meta: { requiresAuth: true },
    },
    {
        path: '/securestring/:token?',
        name: 'SecureStringDownload',
        component: SecureStringDownload,
        props: true,
    },
    //{
    //    path: '/supplicants',
    //    name: 'Supplicants',
    //    component: Supplicants
    //},
    //{
    //    path: '/followInventation',
    //    name: 'Supplicants_FollowInventation',
    //    component: Supplicants
    //},
    // Fallback-Route
    {
        path: '/:pathMatch(.*)*', // F�r Vue Router 4 (Vue 3)
        name: 'Fallback',
        beforeEnter: (to, from, next) => {
            // Umleiten zur Startseite
            next('/');
        }
    }
];

const router = createRouter({
    history: createWebHistory(),
    routes
});
// Globaler Navigation Guard
router.beforeEach((to, from, next) => {
    
    //router.beforeEach(async(to, from, next) => {
    //SystemCheck... funzt nicht... geht nur im Browser, aber nicht via API
    //if (to.path.startsWith('/systemcheck')) {
    //    // API-Key aus dem Pfad auslesen
    //    const apiKey = to.params.apikey;
    //    console.log("to", to);

    //    if (apiKey !== '94503d81-8e2d-42a1-8a7c-8db42233d90e') {
    //        document.body.innerText = '403';
    //        return;
    //    }
    //    // API-Request machen
    //    try {
    //        const url = "api/check/?ApiKey=1ac643bf-cbfc-4a9d-8b12-b611db092e78";

    //        console.log("url", url);

    //        const response = await globals.make_Xhr_Request("GET", url);  
    //        console.log("response", response);

    //        if (response.status === 200) {
    //            document.body.innerText = response.responseText;
    //        } else {
    //            document.body.innerText = response.status;
    //        }
    //        return;
    //    } catch (error) {
    //        console.error('Fehler beim API-Request:', error);
    //        document.body.innerText = 'Error fetching data';
    //        return;
    //    }
    //}

    // Subdomain-Umleitung
    const currentHostname = window.location.hostname;
    if (currentHostname.startsWith('app.') ||
        currentHostname.startsWith('web.')
    ) {
        const home_url = globals.get_home_url(); 
        window.location.href = new URL(to.fullPath, home_url).toString();;
        return;
    }
    
    if (to.name === 'logout') {
        next();
        return;
    }

    security.refresh_jwt().then(() => {
        // JWT wurde erfolgreich aktualisiert, fahren Sie mit der Navigation fort
        const requiresAuth = to.matched.some(record => record.meta.requiresAuth);
        const isLoggedIn = globals.loggedIn; 

        if (requiresAuth && !isLoggedIn) {
            next({
                path: '/login',
                query: { redirect: to.fullPath } // Weitergabe der urspr�nglichen Route als Query-Parameter
            });
        } else {
            next(); // Bei Erf�llung der Bedingung oder keiner Notwendigkeit der Authentifizierung
        }
    }).catch((error) => {
        console.error("Fehler beim JWT-Refresh:", error);
        next('/');
    });
});

export default router;
<template>
    <MarketingPanel class="fadeIn"></MarketingPanel>
    <div id="upload">
        <div id="upload-form" class="fadeIn">
            <div class="site-title">{{ $t('upload.headline')}}</div>
            <form v-on:submit.prevent="init_upload" class="bodyform" autocomplete="off">
                <div class="bodyform__item">
                    <label for="fileInput">{{ $t('upload.form.labels.files')}}</label>
                    <div class="fileInput-wrapper" @dragover="dragover" @dragleave="dragleave" @drop="drop">

                        <div class="file-input">
                            <input type="file" multiple @change="onChangeFiles" ref="file" id="fileInput" :disabled="is_confirmation_required_view_visible">
                        </div>
                        <div class="selectedfiles-div">
                            <div v-if="this.selectedFiles.length" v-cloak class="selectedfiles-items" :class="{'disabled': is_confirmation_required_view_visible}">
                                <FileExplorer :filesTree="selectedFilesTree"></FileExplorer>
                            </div>
                            <div class="selectedfiles-totalSize" v-if="this.selectedFiles.length > 1">
                                <div>{{ helper.formatFileSize(this.selectedFileTotalSize, globals.selected_language)}}</div>
                            </div>
                        </div>
                        <label for="fileInput" v-if="this.selectedFiles.length<1">
                            <div class="button fileInput-button ">
                                <i style="color: #ffffff; --fa-secondary-color: #ffffff; --fa-primary-color: #ffffff;" class="fa-regular fa-3x fa-plus"></i>
                            </div>
                        </label>
                    </div>
                </div>
                <div>
                    
                </div>
                <div class="bodyform__item button-bar button-right less-top-distant">
                    
                    <div v-if="this.selectedFiles.length>=1" class="button-in-button-bar fadeIn">
                        <label for="fileInput">
                            <div class="button square toolbar" :class="{'disabled': is_confirmation_required_view_visible}">
                                <!--<i style="color: #ffffff; --fa-primary-color: #ffffff; --fa-secondary-color: #ffffff;" class="fa-solid fa-lg fa-plus"></i>-->
                                <i class="fa-solid fa-file-plus"></i>
                            </div>
                        </label>
                    </div>
                    <div class="button-in-button-bar" style="position: relative;">
                        <label for="folderInput">
                            <div class="button square toolbar" :class="{'disabled': is_confirmation_required_view_visible}">
                                <i class="fa-solid fa-folder-plus"></i>
                            </div>
                        </label>
                        <div class="file-input">
                            <input type="file" webkitdirectory directory multiple @change="onChangeFolder" ref="folder" id="folderInput" :disabled="is_confirmation_required_view_visible" />
                        </div>
                    </div>
                    <div v-if="this.selectedFiles.length>=1" class="button-in-button-bar fadeIn">
                        <button class="button square toolbar" type="reset" @click="this.reset_upload_panel();">
                            <i style="color: #ffffff;" class="fa-solid fa-trash-can-xmark"></i>
                        </button>
                    </div>

                </div>
                    <div class="bodyform__item error">
                        <div class="error fadeIn" v-if="selectedFileTotalSize_tooLarge">
                            {{ $t('errors.upload_total_too_large', {max_size: helper.formatFileSize(this.limit_max_total_size, globals.selected_language)} )}}
                        </div>
                        <div class="error fadeIn" v-if="upload_max_file_number_exceeded">
                            {{ $t('errors.upload_max_file_number_exceeded', {max_number: limit_max_file_number} )}}
                        </div>
                        <div class="error fadeIn" v-if="upload_file_too_large">
                            {{ $t('errors.upload_file_too_large', {max_size: helper.formatFileSize(this.limit_max_file_size, globals.selected_language)} )}}
                        </div>
                    </div>
                <div class="bodyform__item center-button">
                    <button class="button wide" type="button" @click="show_generate_link_panel();" :disabled="!upload_available">
                        {{ $t('upload.headline_link')}}
                    </button>
                </div>
                <div class="bodyform__item center-button" v-if="limit_show_email_button">
                    <button class="button wide" type="button" @click="show_generate_email_panel()" :disabled="!upload_available">
                        {{ $t('upload.headline_email')}}
                    </button>
                </div>
            </form>
        </div>
    </div>
    <div id="confirmation_required" v-if="is_confirmation_required_view_visible" class="dialog-modal">
        <div class="dialog-container">
            <div class="page_dialog fadeIn less-wide">
                <div class="close-button-bar">
                    <div class="button square toolbar" @click="close_all_upload_dialogs()"><i class="fa-solid fa-xmark"></i></div>
                </div>
                <div class="site-title less-wide">{{ $t('upload.email_confirmation.headline')}}</div>
                <div class="site-content less-wide">{{ $t('upload.email_confirmation.infotext')}}</div>
                <form v-on:submit.prevent="confirm_from_addy" class="bodyform less-wide">
                    <div class="bodyform__item">
                        <label for="confirmation_code">{{ $t('upload.email_confirmation.form.labels.code')}}</label>
                        <input id="confirmation_code" name="confirmation_code" type="text" v-model="confirmation_code" />
                    </div>
                    <div class="bodyform__item two_buttons more_space">
                        <button class="button toolbar" type="button" @click="close_all_upload_dialogs()">{{ $t('upload.email_confirmation.form.labels.cancel')}}</button>
                        <button class="button" type="submit">{{ $t('upload.email_confirmation.form.labels.submit')}}</button>
                    </div>
                </form>
            </div>
        </div>
    </div>

    <div id="upload-successfully" v-if="is_upload_successfully_view_visible" class="dialog-modal">
        <div class="dialog-container">
            <div class="page_dialog fadeIn less-wide">
                <div class="close-button-bar">
                    <div class="button square toolbar" @click="close_all_upload_dialogs()"><i class="fa-solid fa-xmark"></i></div>
                </div>

                <div v-if="share_url" style="position:relative;">
                    <div class="site-title less-wide">{{ $t('upload.success_dialog.headline_link')}}</div>
                    <div class="site-content less-wide">{{ $t('upload.success_dialog.sharelink_info')}}</div>
                    <div class="site-content less-wide">
                        <div class="component-view-list">
                            <div class="component-view-list-body">
                                <div class="component-view-list-body__item framed">
                                    <div class="component-view-list-body__item-content">
                                        <div v-if="!is_the_qr_code_visible" class="component-view-list-body__row">
                                            <div class="share-url">
                                                <a ref="share_url" :href="share_url">{{ share_url }}</a>
                                            </div>
                                        </div>
                                        <div v-if="is_the_qr_code_visible" class="component-view-list-body__row">
                                            <div class="share-url qrcode">
                                                <a :href="share_url">
                                                    <qrcode :value="share_url" :color="{ dark: '#000000ff', light: '#ffffffff' }" errorCorrectionLevel="Q" :width="200" type="image/png"></qrcode>
                                                </a>
                                            </div>
                                        </div>
                                        <div class="component-view-list-body__row button-bar">
                                            <button v-if="!is_the_qr_code_visible && copyToClipBoardButton_state == 0" type="button" @click="copyToClipboard();" class="button square toolbar"><i class="fa-solid fa-copy"></i></button>
                                            <button v-if="!is_the_qr_code_visible && copyToClipBoardButton_state == 1" type="button" @click="copyToClipboard();" class="button square toolbar"><i class="fa-solid fa-check"></i></button>
                                            <button v-if="!is_the_qr_code_visible && copyToClipBoardButton_state == -1" type="button" class="button square toolbar"><i class="fa-solid fa-square-exclamation"></i></button>
                                            <button v-if="!is_the_qr_code_visible" type="button" class="button square toolbar" @click="show_qrcode();"><i class="fa-solid fa-qrcode"></i></button>
                                            <button v-if="is_the_qr_code_visible" type="button" class="button square toolbar" @click="hide_qrcode();"><i class="fa-solid fa-link"></i></button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div v-else>
                    <div class="site-title less-wide">{{ $t('upload.success_dialog.headline_email')}}</div>
                    <div class="site-content less-wide">{{ $t('upload.success_dialog.share_email_info')}}</div>
                </div>
                <div class="site-content less-wide button-right">
                    <button type="button" class="button" @click="close_all_upload_dialogs();">{{ $t('general.buttons.close')}}</button>
                </div>
            </div>
        </div>
    </div>
    <div id="generate-link-form" v-if="is_generate_link_view_visible" class="dialog-modal">
        <div class="dialog-container">
            <div class="page_dialog fadeIn less-wide">
                <div class="close-button-bar">
                    <div class="button square toolbar" @click="show_main_view()"><i class="fa-solid fa-xmark"></i></div>
                </div>
                <div class="site-title less-wide">{{ $t('upload.headline_link')}}</div>
                <div class="dialog-content">
                    <div class="site-content less-wide">
                        <div class="content">
                            {{ $t('upload.infotext_link')}}
                        </div>
                    </div>

                    <form v-on:submit.prevent="init_upload" class="bodyform less-wide" autocomplete="off">
                        <div class="bodyform__item">
                            <label for="subject">{{ $t('upload.form.labels.subject_link')}}</label>
                            <input id="subject" :name="Math.random()" type="text" v-model="subject" autocomplete="off" />
                        </div>
                        <div class="bodyform__item">
                            <label for="message">{{ $t('upload.form.labels.message')}}</label>
                            <CustomTextarea :initValue="message" @update="handleCustomTextareaUpdate(0, $event)"></CustomTextarea>
                        </div>
                        <div v-if="limit_show_password_field" class="bodyform__item">
                            <label for="upload_password">{{ $t('upload.form.labels.password')}}</label>
                            <input id="upload_password" name="upload_password" @focus="handleUploadPasswordFocus($event);" @blur="handleUploadPasswordFocus($event);" type="password" v-model="upload_password" autocomplete="new-password" />
                        </div>

                        <div v-if="limit_show_password_field && is_confirm_passwort_input_visbible" class="bodyform__item">
                            <label for="upload_password_confirm">{{ $t('upload.form.labels.password_confirm')}}</label>
                            <input id="upload_password_confirm" name="upload_password_confirm" type="password" v-model="upload_password_confirm" autocomplete="new-password" />
                        </div>

                        <div v-if="globals.loggedIn" class="bodyform__item">
                            <label for="expiry_date">{{ $t('upload.form.labels.expiry_date')}}</label>
                            <input id="expiry_date" ref="expiry_date" name="expiry_date" type="datetime" v-model="expiry_date" />
                        </div>

                        <div class="bodyform__item two_buttons more_space">
                            <button class="button toolbar" type="button" @click="show_main_view()">{{ $t('general.buttons.cancel')}}</button>
                            <button class="button" type="submit" :disabled="!is_tos_pp_checked">{{ $t('general.buttons.create')}}</button>

                        </div>
                    </form>
                    
                    <div class="site-content less-wide">
                        <div class="content" v-html="$t('general.tos_and_pp_checkbox.infotext')"></div>
                        <div class="content">
                            <label class="custom-checkbox-container">
                                <input type="checkbox" @change="check_tos_pp($event)" name="tos_checkbox" id="tos_checkbox">
                                <span class="checkmark" style="left : 0;"></span>
                                <label for="tos_checkbox" v-html="$t('general.tos_and_pp_checkbox.checkbox')"></label>
                            </label>                            
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div id="generate-email-form" v-if="is_generate_email_view_visible" class="dialog-modal">
        <div class="dialog-container">
            <div class="page_dialog fadeIn less-wide">
                <div class="close-button-bar">
                    <!--<div style="width: 38px; height: 38px; display: flex; flex-direction: column; background-color: antiquewhite; justify-content: center; text-align: center; "><i class="fa-solid fa-xmark"></i></div>-->
                    <div class="button square toolbar" @click="show_main_view()"><i class="fa-solid fa-xmark"></i></div>
                </div>
                <div class="site-title less-wide">{{ $t('upload.headline_email')}}</div>
                <div class="dialog-content">
                    <div class="site-content less-wide">
                        <div class="content">
                            {{ $t('upload.infotext_email')}}
                        </div>
                    </div>
                    <form v-on:submit.prevent="init_upload" class="bodyform less-wide" autocomplete="off">
                        <div class="bodyform__item" v-if="!globals.loggedIn">
                            <label for="fromaddy">{{ $t('upload.form.labels.from_address')}}</label>
                            <input id="fromaddy" name="fromaddy" type="email" v-model="fromaddy" autocomplete="off" :disabled="is_confirmation_required_view_visible" />
                        </div>
                        <div class="bodyform__item">
                            <label for="toaddys">{{ $t('upload.form.labels.to_addresses')}}</label>
                            <div v-for="(toaddy, index) in toaddys" :key="index" class="toaddy-container">
                                <input class="toaddy-input" type="email" v-model="toaddys[index]" />
                                <button class="toaddy-remove-button" type="button" @click="removeToAddy(index)"><i style="color: #ffffff;" class="fa-solid fa-trash-can-xmark"></i></button>
                            </div>
                        </div>
                        <div class="bodyform__item button-right">


                            <button v-if="max_toaddys_length" type="button" class="toaddy-add-button" @click="addToAddy()" :disabled="toaddys.length >= max_toaddys_length ">
                                <i style="color: #ffffff; --fa-primary-color: #ffffff; --fa-secondary-color: #ffffff;" class="fa-solid fa-plus"></i>
                            </button>
                            <button v-else type="button" class="toaddy-add-button" @click="addToAddy()">
                                <i style="color: #ffffff; --fa-primary-color: #ffffff; --fa-secondary-color: #ffffff;" class="fa-solid fa-plus"></i>
                            </button>


                        </div>
                        <div class="bodyform__item">
                            <label for="subject">{{ $t('upload.form.labels.subject')}}</label>
                            <input id="subject" :name="Math.random()" type="text" v-model="subject" autocomplete="off" :disabled="is_confirmation_required_view_visible" />
                        </div>

                        <div class="bodyform__item">
                            <label for="message">{{ $t('upload.form.labels.message')}}</label>
                            <CustomTextarea :initValue="message" @update="handleCustomTextareaUpdate(0, $event)" :disabled="is_confirmation_required_view_visible"></CustomTextarea>
                        </div>

                        <div v-if="limit_show_password_field" class="bodyform__item">
                            <label for="upload_password">{{ $t('upload.form.labels.password')}}</label>
                            <input id="upload_password" name="upload_password" type="password" @focus="handleUploadPasswordFocus($event);" @blur="handleUploadPasswordFocus($event);" v-model="upload_password" autocomplete="new-password" :disabled="is_confirmation_required_view_visible" />
                        </div>

                        <div v-if="limit_show_password_field && is_confirm_passwort_input_visbible" class="bodyform__item">
                            <label for="upload_password_confirm">{{ $t('upload.form.labels.password_confirm')}}</label>
                            <input id="upload_password_confirm" name="upload_password_confirm" type="password" v-model="upload_password_confirm" autocomplete="new-password" :disabled="is_confirmation_required_view_visible" />
                        </div>

                        <div v-if="globals.loggedIn" class="bodyform__item">
                            <label for="expiry_date">{{ $t('upload.form.labels.expiry_date')}}</label>
                            <input id="expiry_date" ref="expiry_date" name="expiry_date" type="datetime" v-model="expiry_date" :disabled="is_confirmation_required_view_visible" />
                        </div>

                        <div class="bodyform__item two_buttons more_space">
                            <button class="button toolbar" type="button" @click="show_main_view()">{{ $t('general.buttons.cancel')}}</button>
                            <button class="button" type="submit" :disabled="!is_tos_pp_checked">{{ $t('upload.form.labels.submit')}}</button>
                        </div>
                    </form>
                    <div class="site-content less-wide">
                        <div class="content" v-html="$t('general.tos_and_pp_checkbox.infotext')"></div>
                        <div class="content">
                            <label class="custom-checkbox-container">
                                <input type="checkbox" @change="check_tos_pp($event)" name="tos_checkbox" id="tos_checkbox">
                                <span class="checkmark" style="left : 0;"></span>
                                <label for="tos_checkbox" v-html="$t('general.tos_and_pp_checkbox.checkbox')"></label>
                            </label>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
    import Qrcode from 'vue-qrcode';
    import { v4 as uuidv4 } from 'uuid';

    import MarketingPanel from '../components/MarketingPanel.vue';
    import CustomTextarea from '../components/CustomTextarea.vue';
    import FileExplorer from '../components/FileExplorer.vue';

    import helper from '../utils/Helper';
    import globals from '../utils/Globals';
    import security from '../utils/Security';
    import validator from 'validator';

    export default {
        name: 'FileShareUpload',
        setup() {
            return {
                helper, globals, security
            };
        },
        components: {
            Qrcode, MarketingPanel, CustomTextarea, FileExplorer
        },
        props: {
            msg: String
        },
        data: () => ({
            selectedFiles: [],
            dropedFiles: [],
            subject: '',
            message: '',
            fromaddy: '',
            toaddys: [''],
            max_toaddys_length: 2,
            upload_password: '',
            upload_password_confirm: '',
            expiry_date: '',
            upload_token: '',
            confirmation_code_token: '',
            confirmation_code: '',
            share_token: '',
            share_url: '',            
            dateBox: '',
            flatpickrOb: null,
            is_generate_link_view_visible: false,
            is_generate_email_view_visible: false,
            is_upload_successfully_view_visible: false,
            is_confirmation_required_view_visible: false,
            is_confirm_passwort_input_visbible: false,
            is_tos_pp_checked: false,

            limit_max_file_size:'',
            limit_max_total_size:'',
            limit_max_file_number:'',
            limit_max_days_to_expire:'',
            limit_max_number_of_email_recipients: '',
            limit_show_email_button: false,
            limit_show_password_field: false,

            upload_max_file_number_exceeded: false,
            selectedFileTotalSize_tooLarge: false,
            upload_file_too_large: false,

            is_the_qr_code_visible: false,
            copyToClipBoardButton_state: 0,

        }),
        computed: {
            selectedFileTotalSize() {
                let totalSize = 0;
                for (let i = 0; i < this.selectedFiles.length; i++) {
                    totalSize = totalSize + this.selectedFiles[i].size
                }
                //console.log("totalSize", helper.formatFileSize(totalSize, globals.selected_language));
                return totalSize;
            },
            selectedFilesTree() {
                const limits = {
                    maxFileSize: this.limit_max_file_size,
                };
                return helper.buildFileTreeFromFlatFileArray(this.selectedFiles, null, limits);
            },
            upload_available() {
                return this.selectedFiles.length > 0
                    && !this.upload_file_too_large
                    && !this.upload_max_file_number_exceeded
                    && !this.selectedFileTotalSize_tooLarge;
            }
        },
        provide() {
            return {
                removeSelectedFileById: this.removeSelectedFileById,
                is_confirmation_required_view_visible: this.is_confirmation_required_view_visible,
            };
        },
        methods: {
            async get_limits() {
                const url = "upload/limits/" + globals.provider + "/" + globals.service;
                try {
                    const response = await this.globals.make_Xhr_Request("GET", url);

                    if (response.status === 200) {
                        var result = JSON.parse(response.responseText);

                        this.limit_available_upload_methods = result.payload.available_upload_methods;
                        this.limit_max_days_to_expire = result.payload.max_days_to_expiry;
                        this.limit_max_file_size = result.payload.max_file_size;
                        globals.max_file_size = result.payload.max_file_size;
                        this.limit_max_total_size = result.payload.max_total_size;
                        this.limit_max_file_number = result.payload.max_file_number;
                        this.limit_max_number_of_email_recipients = result.payload.max_number_of_email_recipients;
                        this.limit_show_email_button = result.payload.available_upload_methods.includes("Email");
                        this.limit_show_password_field = result.payload.available_upload_methods.includes("Password");
                        this.max_toaddys_length = this.limit_max_number_of_email_recipients;

                        //console.log(result);
                        
                    } else {
                        globals.handle_Xhr_Errors(response.status, response.responseText);
                    }
                } catch (e) {
                    console.error("get_limits failed", e);
                }
                finally {
                    this.globals.hide_loading();
                }
            },
            async init_upload() {
                if (this.selectedFiles.length < 1) {
                    globals.show_error('errors.no_files_selected');
                    return;
                }
                if (this.limit_show_password_field && (this.upload_password + "") !== (this.upload_password_confirm + "")) {
                    globals.show_error('errors.password_not_match');
                    return;
                }
                let to_addresses = [];
                //console.log("is_generate_email_view_visible", this.is_generate_email_view_visible);
                if (this.is_generate_email_view_visible) {
                    let errors = [];
                    errors.push(this.$i18next.t('upload.form.errors.intro'))
                    if (!globals.loggedIn) {
                        if (!this.fromaddy) {
                            errors.push("- " + this.$i18next.t('upload.form.errors.requiered_from'));
                        } else {
                            if (!validator.isEmail(this.fromaddy.trim())) {
                                errors.push("- " + this.$i18next.t('upload.form.errors.valid_from', { emailaddy: this.fromaddy.trim() }));
                            }
                        }
                    }

                    
                    for (var i = 0; i < this.toaddys.length; i++) {
                        let tempAddy = this.toaddys[i].trim();
                        if (tempAddy.length > 0) {
                            to_addresses.push(tempAddy);
                        }
                    }
                    if (to_addresses.length > 0) {
                        if (to_addresses.length > this.max_toaddys_length) {
                            errors.push("- " + this.$i18next.t('upload.form.errors.to_count', { maxcount: this.max_toaddys_length }));
                        }
                        for (var i = 0; i < to_addresses.length; i++) {
                            let tempAddy = to_addresses[i].trim();

                            if (!validator.isEmail(tempAddy)) {
                                errors.push("- " + this.$i18next.t('upload.form.errors.valid_to', { emailaddy: tempAddy }));
                            }
                        }
                    } else {
                        errors.push("- " + this.$i18next.t('upload.form.errors.requiered_to'));
                    }                    

                    //if (!this.toaddys.trim()) {
                    //    errors.push("- " + this.$i18next.t('upload.form.errors.requiered_to'));
                    //} else {
                    //    to_addresses = this.toaddys.trim().split(/[\s,;]+/);
                    //    const maxLength = globals.loggedIn ? 5 : 2;
                    //    if (to_addresses.length > maxLength) {
                    //        errors.push("- " + this.$i18next.t('upload.form.errors.to_count', { maxcount: maxLength }));
                    //    }                        
                    //    for (var i = 0; i < to_addresses.length; i++) {
                    //        let tempAddy = to_addresses[i].trim();

                    //        if (!validator.isEmail(tempAddy)) {
                    //            errors.push("- " + this.$i18next.t('upload.form.errors.valid_to', { emailaddy: tempAddy }));
                    //        } 
                    //    }
                    //}
                    if (!this.subject) {
                        errors.push("- " + this.$i18next.t('upload.form.errors.requiered_subject'));
                    }
                    if (errors.length > 1) {
                        globals.errors = errors;
                        return;
                    }
                }

                const url = "upload/init";
                const initRequestData = {
                    subject: this.subject,
                    message: this.message,
                    service: globals.service,
                    from_address: this.fromaddy,
                    password: this.upload_password,
                    expiry_date: this.expiry_date !== '' ? this.flatpickrOb.selectedDates[0] : null,
                    to_addresses: to_addresses,
                };

                // Leere Felder aus dem JSON-Objekt entfernen
                const filteredData = {};
                for (const key in initRequestData) {
                    if (initRequestData[key] !== '') {
                        filteredData[key] = initRequestData[key];
                    }
                }

                try {
                    const response = await this.globals.make_Xhr_Request("POST", url, filteredData);
                    if (response.status === 200) {
                        var result = JSON.parse(response.responseText);
                        this.upload_token = result.payload.upload_token;
                        this.confirmation_code_token = result.payload.confirmation_code_token;
                        if (this.upload_token) {
                            await this.start_upload(result.payload.share_tokens);
                        } else if (this.confirmation_code_token) {
                            this.is_confirmation_required_view_visible = true;
                            this.is_generate_email_view_visible = false;
                            window.scrollTo(0, document.body.scrollHeight);
                        } else {
                            globals.show_error('errors.not_ok_status');
                        }
                    } else if (response.status === 400) {
                        globals.errors.push("Daten zum Versand unvollständig. ");
                        var splitz = response.responseText.split(/[;]+/);
                        for (var i = 0; i < splitz.length; i++) {
                            globals.errors.push("- " + splitz[i]);
                        }
                    } else {
                        globals.handle_Xhr_Errors(response.status, response.responseText);
                    }
                } catch (e) {
                    console.error("init_upload failed", e);
                }
                finally {
                    this.globals.hide_loading();
                }
            },
            async confirm_from_addy() {

                const url = "upload/confirm/" + this.confirmation_code_token
                const data = { code: this.confirmation_code };

                try {
                    const response = await globals.make_Xhr_Request("POST", url, data);

                    if (response.status === 200) {
                        var result = JSON.parse(response.responseText);
                        this.upload_token = result.payload.upload_token;
                        if (this.upload_token) {
                            await this.start_upload(result.payload.share_tokens);
                        } else {
                            globals.show_error('errors.not_ok_status');
                        }

                    } else if (response.status === 401) {
                        globals.show_error('errors.invalid_code');
                    } else {
                        globals.handle_Xhr_Errors(response.status, response.responseText);
                    }
                } catch (e) {
                    console.error("confirm_from_addy failed", e);
                }
                finally {
                    globals.hide_loading();
                }
            },
            async start_upload(share_tokens) {

                const url = "upload/encrypted-files/" + this.upload_token;
                let formData = new FormData();
                // Alle ausgewählten Dateien zum FormData-Objekt hinzufügen
                let share_token_encryption_infos = [];
                for (const share_token of share_tokens) {

                    const pw = security.generate_pw(); //für Server
                    const salt = security.generate_salt(); //für Server
                    const personal_key = await security.generate_personal_key(pw, salt); //nur Temp 
                    const edki = await security.generate_encrypted_database_key_info(personal_key);
                    //console.log("edki.encryptedOutput", edki.encryptedOutput);
                    //console.log("edki.iv", edki.iv);
                    //console.log("personal_key", personal_key);

                    const dbKey = await security.process_decryption_as_crypto_key(edki.encryptedOutput, edki.iv, personal_key);
                    share_token_encryption_infos.push(
                        {
                            id: share_token, //für Server - DB
                            pw: pw, //für Server - Mail
                            salt: salt, //für Server - DB
                            encrpyt_dbKey: edki.encryptedOutput,//für Server - DB
                            encrpyt_dbKey_iv: edki.iv,//für Server -DB
                            dbKey: dbKey, //nur Temporär
                        }
                    );
                }
                //console.log("bin hier###1");
                //console.log("bin hier###1a", security.encrypted_database_key);
                //console.log("bin hier###1b", security.database_key_iv);
                //console.log("bin hier###1c", security.personal_key);
                const dbkey = await security.process_decryption_as_crypto_key(security.encrypted_database_key, security.database_key_iv, security.personal_key)
                //console.log("bin hier###2");
                for (let i = 0; i < this.selectedFiles.length; i++) {
                    const file = this.selectedFiles[i];
                    const encrypted_data = await security.process_encryptionFile(file, dbkey, share_token_encryption_infos);
                    formData.append('file[' + i + ']', encrypted_data.encryptedFileData);
                    formData.append('fileKey[' + i + ']', encrypted_data.encryptedFileKey);
                    formData.append('fileDbKeyId[' + i + ']', security.database_key_id);
                    formData.append('fileIV[' + i + ']', encrypted_data.fileIV);
                    formData.append('fileInfo[' + i + ']', encrypted_data.encryptedFileInfos);
                    formData.append('fileShares[' + i + ']', JSON.stringify(encrypted_data.share_token_file_encryption_infos));
                }

                try {
                    const response = await globals.make_Xhr_AbortableRequest("POST", url, formData);
                    //console.log("response", response);
                    if (response.status === 200) {
                        var result = JSON.parse(response.responseText);
                        this.share_token = result.payload.share_token;
                        this.share_url = result.payload.share_url;
                        this.is_upload_successfully_view_visible = true;
                        this.is_generate_link_view_visible = false;
                        this.is_generate_email_view_visible = false;
                        this.is_confirmation_required_view_visible = false;
                        this.reset_upload_dialog_inputs();

                    } else if (response.status === 403) {
                        globals.show_error('errors.upload_rejected');
                    } else if (response.status === 401) {
                        globals.show_error('errors.upload_rejected');
                    } else if (response.status === 0) {
                        //abgebrochen
                    } else {
                        globals.handle_Xhr_Errors(response.status, response.responseText);
                    }
                } catch (e) {
                    console.error("start_upload failed", e);
                }
                finally {
                    globals.hide_loading();
                }
            },
            //onFileChange: function (event) {
            //    // Array der ausgewählten Dateien aktualisieren
            //    this.selectedFiles = Array.from(event.target.files);
                
            //    console.log("onFileChange", this.selectedFiles);
            //},
            removeToAddy(index) {
                if (this.toaddys.length > 1) {
                    this.toaddys.splice(index, 1); // Entfernt das ausgewählte Email-Feld
                }
            },
            addToAddy() {
                this.toaddys.push(''); // Fügt ein neues leeres Email-Feld hinzu
            },
            removeSelectedFile(index) {
                if (!this.is_confirmation_required_view_visible) {
                    this.selectedFiles.splice(index, 1);
                }
            },
            removeSelectedFileById(id) {
                if (!this.is_confirmation_required_view_visible) {
                    if (id.startsWith("folder_")) {
                        const idsToRemoveSet = helper.listAllItemIdsInFolder(id, this.selectedFilesTree);
                        this.selectedFiles = this.selectedFiles.filter(file => !idsToRemoveSet.has(file.id));
                    } else {
                        this.selectedFiles = this.selectedFiles.filter(d => d.id !== id);
                    }
                }                
            },
            check_limits() {

                if (this.limit_max_total_size) {
                    this.selectedFileTotalSize_tooLarge = this.selectedFileTotalSize > this.limit_max_total_size;
                }
                console.log("this.selectedFiles.length", this.selectedFiles.length);
                if (this.limit_max_file_number) {
                    this.upload_max_file_number_exceeded = this.selectedFiles.length > this.limit_max_file_number;
                }
                this.upload_file_too_large = false;
                if (this.limit_max_file_size) {
                    for (let i = 0; i < this.selectedFiles.length; i++) {
                        if (this.selectedFiles[i].size > this.limit_max_file_size) {
                            this.upload_file_too_large = true;
                            break;
                        }
                        
                    }
                }

            },
            onChangeFiles() {
                const uploadId = uuidv4();
                for (let i = 0; i < this.$refs.file.files.length; i++) {
                    //this.selectedFiles.push(this.$refs.file.files[i]);
                    let file = this.$refs.file.files[i];
                    file.id = uuidv4();
                    file.uploadId = uploadId;
                    file.relativePath = file.webkitRelativePath;
                    this.selectedFiles = [...this.selectedFiles, file];
                }                
                this.$refs.file.value = '';
            },
            onChangeFolder() {
                const uploadId = uuidv4();
                for (let i = 0; i < this.$refs.folder.files.length; i++) {
                    //this.selectedFiles.push(this.$refs.folder.files[i]);
                    let file = this.$refs.folder.files[i];
                    file.id = uuidv4();
                    file.uploadId = uploadId;
                    file.relativePath = file.webkitRelativePath;
                    this.selectedFiles = [...this.selectedFiles, file];
                }
                this.$refs.folder.value = '';
                //console.log("onChangeFolder", this.selectedFiles);
            },
            dragover(event) {
                event.preventDefault();
                // Add some visual fluff to show the user can drop its files
                if (!event.currentTarget.classList.contains('upload-bg-green-300')) {
                    event.currentTarget.classList.remove('upload-bg-gray-100');
                    event.currentTarget.classList.add('upload-bg-green-300');
                }
            },
            dragleave(event) {
                // Clean up
                event.currentTarget.classList.add('upload-bg-gray-100');
                event.currentTarget.classList.remove('upload-bg-green-300');
            },
            drop(event) {
                globals.show_loading();
                event.preventDefault();
                this.dropedFiles = []; // Reset des Arrays bei jedem neuen Drop
                const items = event.dataTransfer.items;
                const promises = [];
                const uploadId = uuidv4();
                if (items) {
                    for (let i = 0; i < items.length; i++) {
                        const item = items[i].webkitGetAsEntry();
                        if (item) {
                            promises.push(this.scanFiles(item, "", uploadId));
                        }
                    }
                }
                Promise.all(promises).then(() => {
                    this.selectedFiles = [...this.selectedFiles, ...this.dropedFiles];
                    globals.hide_loading();
                    // Clean up
                    this.dropedFiles = [];
                });
                event.currentTarget.classList.add('upload-bg-gray-100');
                event.currentTarget.classList.remove('upload-bg-green-300');
            },
            scanFiles(item, path = "", uploadId) {
                //console.log("uploadId in scanfiles", uploadId);
                return new Promise((resolve) => {
                    if (item.isFile) {
                        item.file(file => {
                            
                            file.id = uuidv4();
                            file.relativePath = path + file.name;
                            file.uploadId = uploadId;
                            //console.log("file", file);
                            //this.selectedFiles = [...this.selectedFiles, file];
                            this.dropedFiles = [...this.dropedFiles, file];
                            resolve();
                        });
                    } else if (item.isDirectory) {
                        const reader = item.createReader();
                        reader.readEntries(entries => {
                            const dirPromises = [];
                            for (const entry of entries) {
                                dirPromises.push(this.scanFiles(entry, path + item.name + "/", uploadId));
                            }
                            Promise.all(dirPromises).then(resolve);
                        });
                    } else {
                        resolve(); // Falls es weder eine Datei noch ein Verzeichnis ist, einfach auflösen
                    }
                });
            },
            show_main_view() {
                this.is_generate_link_view_visible = false;
                this.is_generate_email_view_visible = false;
                this.is_tos_pp_checked = false;
                this.reset_upload_dialog_inputs();
            },
            show_generate_link_panel() {
                this.is_generate_link_view_visible = true;
                this.is_tos_pp_checked = false;
            },
            show_generate_email_panel() {
                this.is_generate_email_view_visible = true;
                this.is_tos_pp_checked = false;
            },
            close_all_upload_dialogs() {
                this.is_generate_link_view_visible = false;
                this.is_generate_email_view_visible = false;
                this.is_upload_successfully_view_visible = false;
                this.is_confirmation_required_view_visible = false;
                this.reset_upload_dialog_inputs();
            },
            reset_upload_panel() {
                this.selectedFiles = [];
                this.$refs.folder.value = '';
                this.$refs.file.value = '';
                
                this.reset_upload_dialog_inputs();
                this.is_confirmation_required_view_visible = false;
                this.is_upload_successfully_view_visible = false;
                this.is_generate_link_view_visible = false;
                this.is_generate_email_view_visible = false;

                this.upload_token = '';
                this.confirmation_code_token = '';
                this.confirmation_code = '';
                this.share_token = '';
                this.share_url = '';
            },
            reset_upload_dialog_inputs() {
                this.subject = '';
                this.message = '';
                this.fromaddy = '';
                this.toaddys = [''];
                this.upload_password = '';
                this.upload_password_confirm = '';
                this.expiry_date = '';
                this.is_tos_pp_checked = false;
            },
            copyToClipboard() {
                helper.copyToClipboard(this.share_url, (newState) => {
                    this.copyToClipBoardButton_state = newState;
                });
            },
            show_qrcode() {
                this.is_the_qr_code_visible = true;
            },
            hide_qrcode() {
                this.is_the_qr_code_visible = false;
            },
            initializeFlatpickr(dateBox) {
                const locale = globals.selected_language === 'de' ? flatpickr.l10ns.de : flatpickr.l10ns.default;
                const dateFormat = globals.selected_language === 'de' ? 'd. M Y H:i' : 'M/d/Y h:i K';

                let maxDate = null;
                if (this.limit_max_days_to_expire) {
                    maxDate = new Date().fp_incr(this.limit_max_days_to_expire);
                }
                this.flatpickrOb = flatpickr(dateBox, {
                    enableTime: true, 
                    minDate: "today",
                    maxDate: maxDate,
                    locale: locale,
                    dateFormat: dateFormat,
                });
            },
            check_tos_pp(event) {
                const target = event.target;
                this.is_tos_pp_checked = target.checked;
            },
            handleCustomTextareaUpdate(index, content) {
                if (index === 0) {
                    //console.log(content);
                    this.message = content
                }
                
            },
            handleUploadPasswordFocus(event) {
                //console.log("event:", event);
                if (event.type === 'focus') {
                    this.is_confirm_passwort_input_visbible = true;
                }
                if (event.type === 'blur') {
                    if (this.upload_password.length <= 0) {
                        this.is_confirm_passwort_input_visbible = false;
                        this.upload_password_confirm = '';
                    }
                }
            },
            handleKeyDown(event) {
                //if (event.key === 'Escape') {
                //    if (this.is_upload_successfully_view_visible)
                //        this.reset_upload_panel();
                //    else if (this.is_confirmation_required_view_visible)
                //        this.reset_upload_panel();
                //}
            }
        },
        watch: {
            'globals.selected_language': {
                handler: function (newVal, oldVal) {
                    if (this.flatpickrOb !== null) {
                        this.flatpickrOb.destroy();
                        this.initializeFlatpickr(this.dateBox);
                    }
                },
                immediate: true
            },
            'selectedFileTotalSize': {
                handler: function () {
                    this.check_limits();
                }
            },
            'globals.loggedIn': {
                handler: async function () {
                    await this.get_limits();
                    this.check_limits();
                }
            }
        },
        mounted: async function () {
            document.addEventListener('keydown', this.handleKeyDown);
            globals.setPageTitel("upload.headline");
            await this.get_limits();
            
        },
        beforeDestroy() {
            document.removeEventListener('keydown', this.handleKeyDown);
            this.flatpickrOb.destroy();
            this.flatpickrOb = null;

        },
        updated: async function () {
            //await this.get_limits(); //führt zu einem Fehler beim Bewegen der Maus?! 
            if (this.$refs.expiry_date && !this.flatpickrOb) {
                this.dateBox = this.$refs.expiry_date;
                this.initializeFlatpickr(this.dateBox);
            }
            if (!this.$refs.expiry_date) {
                this.flatpickrOb = null;
            }
            
            
        }
    }
</script>
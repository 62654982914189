<template>
    <div id="history" class="fadeIn">
        <div class="site-title">{{ $t('history.headline')}}</div>
        <div v-if="this.history_items.length>0" class="site-content">
            <div class="component-view-list">
                <div v-if="this.history_items.length>1" class="component-view-list-header right-align">
                    <div class="component-view-list-sort">
                        <CustomDropdown :optionsList="sortOptions" :defaultLabel="'download.listview.toolbarpanel.sort.placeholder'" @option-selected="handleSortSelected"></CustomDropdown>
                    </div>
                </div>
                <div class="component-view-list-body">
                    <div v-for="history_item in this.filterHistoryItems" :key="history_item.id" class="component-view-list-body__item framed">
                        <div class="component-view-list-body__item-content">
                            <div class="component-view-list-body__row">
                                <div class="share-files-tr file-infos">
                                    <div class="file-icon combined">
                                        <div class="file-icon-symbol">
                                            <i :class="['fas fa-2x fa-file']"></i>
                                        </div>
                                        <div class="file-icon-badge">{{history_item.files.length}}</div>
                                    </div>
                                    <div class="file-info-columns">
                                        <div class="file-share-infos" v-if="history_item.to_address">
                                            <div class="share-files-th">{{ $t('history.listview.listitems.labels.to_email')}}</div>
                                            <div class="share-files-td">{{ history_item.to_address }}</div>
                                        </div>
                                        <div class="file-share-infos" v-if="history_item.subject_text">
                                            <div class="share-files-th" v-if="history_item.to_address">{{ $t('download.header.labels.subject')}}</div>
                                            <div class="share-files-th" v-if="!history_item.to_address">{{ $t('download.header.labels.subject_link')}}</div>
                                            <div class="share-files-td">{{ history_item.subject_text }}</div>
                                        </div>
                                        <div class="file-share-infos" v-if="history_item.created_on">
                                            <div class="share-files-th" v-if="history_item.to_address">{{ $t('download.header.labels.created_on')}}</div>
                                            <div class="share-files-th" v-if="!history_item.to_address">{{ $t('download.header.labels.created_on_link')}}</div>
                                            <div class="share-files-td">{{ helper.formatReadableDate(history_item.created_on, globals.selected_language)  }}</div>
                                        </div>
                                        <div class="file-share-infos" v-if="history_item.created_on">
                                            <div class="share-files-th">{{ $t('download.header.labels.expiry_date')}}</div>
                                            <div class="share-files-td">{{ helper.formatReadableDate(history_item.expiry_date, globals.selected_language)  }}</div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="component-view-list-body__row button-bar">
                                <button type="button"
                                        class="button square"
                                        @click="show_file_share_view($event)"
                                        :data-id="history_item.id">
                                    <i class="fa-solid fa-eye"></i>
                                </button>
                                <button type="button"
                                        class="button square toolbar"
                                        :title="$t('history.listview.listitems.buttons.show_logs_tooltip')"
                                        @click="show_logs($event);"
                                        :data-id="history_item.id">
                                    <i class="fa-solid fa-list"></i>
                                </button>
                                <button type="button"
                                        class="button square toolbar"
                                        :title="$t('history.listview.listitems.buttons.delete_tooltip')"
                                        @click="delete_download($event);"
                                        :data-id="history_item.id">
                                    <i class="fa-solid fa-trash-xmark"></i>
                                </button>
                                <button type="button"
                                        class="button square toolbar"
                                        :title="$t('history.listview.listitems.buttons.expand_expiry_date_tooltip')"
                                        @click="show_set_new_expiry_date_dialog($event)"
                                        :data-id="history_item.id"
                                        :data-expiry-date="history_item.expiry_date">
                                    <i class="fa-solid fa-calendar-plus"></i>
                                </button>
                                <button type="button" class="button square toolbar" @click="show_set_new_password_dialog($event)" :data-id="history_item.id" :data-has-password="history_item.has_password">
                                    <i v-if="history_item.has_password" class="fa-solid fa-lock" :title="$t('history.listview.listitems.buttons.reset_password_tooltip')"></i>
                                    <i v-else class="fa-solid fa-lock-open" :title="$t('history.listview.listitems.buttons.set_password_tooltip')"></i>
                                </button>
                            </div>
                        </div>
                    </div>
                </div>





                <!--<div class="component-view-list-body__row more_space">
                <div class="component-view-list-body__label">{{ $t('history.listview.listitems.labels.files') }}</div>
                <div class="component-view-list-body__content">
                    <div style="min-width:50px; display:inline-block;">{{ (history_item.files.length) }}</div>
                    <div style="display: inline-block;" @click="toggleVisibility(history_item.id)">-->
                <!--<a>-->
                <!--<span v-if="isOpen['shto'+history_item.id]" class="fa-solid fa-eye-slash" :title="$t('history.listview.listitems.buttons.hide_sharetoken_stats')">Anzeigen</span>-->
                <!--<span v-else :title="$t('history.listview.listitems.buttons.show_sharetoken_stats')">Anzeigen</span>-->
                <!--<i v-else class="fa-solid fa-eye" :title="$t('history.listview.listitems.buttons.show_sharetoken_stats')"></i>-->
                <!--</a>-->
                <!--<button v-if="isOpen[history_item.id]" :title="$t('history.listview.listitems.buttons.hide_files_tooltip')" class="button xsmall toolbar">{{ $t('general.buttons.hide_info')}}</button>
                            <button v-else :title="$t('history.listview.listitems.buttons.show_files_tooltip')" class="button xsmall toolbar">{{ $t('general.buttons.show_info')}}</button>
                        </div>
                    </div>
                </div>
                <div class="component-view-list-body__row more_space">
                    <div class="component-view-list-body__label"></div>
                    <div class="component-view-list-body__content">
                        <div class="share-files" v-show="isOpen[history_item.id]">
                            <div v-for="file in history_item.files" :key="file.id" class="share-files-div invers">
                                <div class="share-files-tr file-infos">
                                    <div class="file-icon"><i :class="['fas fa-2x', helper.getFileIconByContentType(file.contenttype)]"></i></div>
                                    <div class="file-info-columns">
                                        <div class="file-name">
                                            <div class="share-files-th">{{ $t('download.files.labels.name')}}</div>
                                            <div class="share-files-td">{{ file.filename }}</div>
                                        </div>
                                        <div class="file-size">
                                            <div class="share-files-th">{{ $t('download.files.labels.size')}}</div>
                                            <div class="share-files-td">{{ helper.formatFileSize(file.size, globals.selected_language) }}</div>
                                        </div>
                                    </div>
                                </div>
                                <div class="share-files-tr dl-button">
                                    <div class="share-files-td">
                                        <button type="button" class="button small toolbar" @click="this.get_download_url($event)" :data-token="file.token" :data-id="file.id" :data-parentId="history_item.id" :data-contenttype="file.contenttype" :data-filename="file.filename">{{ $t('download.files.labels.download')}}</button>
                                    </div>
                                    <div style="clear:both;"></div>
                                </div>
                                <div class="share-files-tr file-infos">
                                    <div class="file-icon"></div>
                                    <div class="file-info-columns">
                                        <div class="file-downloads">
                                            <div class="share-files-th">{{ $t('history.listview.listitems.labels.downloads')}}</div>
                                            <div class="share-files-td">
                                                <div style="min-width:50px; display:inline-block;">{{ this.sumDownloads(file.download_stats) }}</div>
                                                <div style="display: inline-block;" v-if="this.sumDownloads(file.download_stats)>0" @click="toggleVisibility('dost'+file.id)">-->
                <!--<a>-->
                <!--<span v-if="isOpen['shto'+history_item.id]" class="fa-solid fa-eye-slash" :title="$t('history.listview.listitems.buttons.hide_sharetoken_stats')">Anzeigen</span>-->
                <!--<span v-else :title="$t('history.listview.listitems.buttons.show_sharetoken_stats')">Anzeigen</span>-->
                <!--<i v-else class="fa-solid fa-eye" :title="$t('history.listview.listitems.buttons.show_sharetoken_stats')"></i>-->
                <!--</a>-->
                <!--<button v-if="isOpen['dost'+file.id]" :title="$t('history.listview.listitems.buttons.hide_download_stats')" class="button xsmall toolbar">{{ $t('general.buttons.hide_info')}}</button>
                                                    <button v-else :title="$t('history.listview.listitems.buttons.show_download_stats')" class="button xsmall toolbar">{{ $t('general.buttons.show_info')}}</button>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div class="share-files-tr file-infos" v-show="isOpen['dost'+file.id]">
                                    <div class="file-icon"></div>
                                    <div class="file-info-columns">
                                        <div v-if="file.download_stats.length>0">
                                            <div v-for="download_stat in file.download_stats" :key="download_stat.token" style="padding-bottom: 2px; padding-top: 2px;">
                                                <div class="share-files-th"></div>

                                                <div v-if="download_stat.email" class="share-files-td">{{download_stat.download_counter}}x von {{download_stat.email}} ({{helper.formatReadableDate(download_stat.first_downloaded_on, globals.selected_language)}})</div>

                                                <div v-else class="share-files-td">{{download_stat.download_counter}}x über Link-Freigabe ({{helper.formatReadableDate(download_stat.first_downloaded_on, globals.selected_language)}})</div>
                                            </div>
                                        </div>
                                        <div v-else>{{ $t('history.listview.listitems.labels.no_download_stats')}}</div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div class="component-view-list-body__row more_space">
                    <div class="component-view-list-body__label">{{ $t('history.listview.listitems.labels.shares') }}</div>
                    <div class="component-view-list-body__content">
                        <div style="min-width:50px; display:inline-block;">{{ history_item.share_tokens.length }}</div>
                        <div style="display: inline-block;" @click="toggleVisibility('shto'+history_item.id)">-->
                <!--<a>-->
                <!--<span v-if="isOpen['shto'+history_item.id]" class="fa-solid fa-eye-slash" :title="$t('history.listview.listitems.buttons.hide_sharetoken_stats')">Anzeigen</span>-->
                <!--<span v-else :title="$t('history.listview.listitems.buttons.show_sharetoken_stats')">Anzeigen</span>-->
                <!--<i v-else class="fa-solid fa-eye" :title="$t('history.listview.listitems.buttons.show_sharetoken_stats')"></i>-->
                <!--</a>-->
                <!--<button v-if="isOpen['shto'+history_item.id]" :title="$t('history.listview.listitems.buttons.hide_sharetoken_stats')" class="button xsmall toolbar">{{ $t('general.buttons.hide_info')}}</button>
                                    <button v-else :title="$t('history.listview.listitems.buttons.show_sharetoken_stats')" class="button xsmall toolbar">{{ $t('general.buttons.show_info')}}</button>
                                </div>
                            </div>
                        </div>

                        <div v-show="isOpen['shto'+history_item.id]" class="component-view-list-body__row more_space">
                            <div class="component-view-list-body__label"></div>
                            <div class="component-view-list-body__content">
                                <div v-for="share_token in history_item.share_tokens" :key="share_token.token" class="">
                                    <div style="padding-bottom:3px;">
                                        <span v-if="share_token.email">{{share_token.email}}</span>
                                        <span v-else>{{ $t('history.listview.listitems.labels.sharetoken_text_url')}}</span>
                                        <span v-if="share_token.first_opened_on"> ({{ $t('history.listview.listitems.labels.sharetoken_text_opend')}} {{helper.formatReadableDate(share_token.first_opened_on, globals.selected_language)}})</span>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="component-view-list-body__toolbar">
                        <div ><a :data-id="history_item.id" @click="delete_download($event);"></a></div>
                        <div ><a ></a></div>
                        <div >
                            <a>
                                <i v-if="history_item.has_password" class="fa-solid fa-lock" :title="$t('history.listview.listitems.buttons.reset_password_tooltip')"></i>
                                <i v-else class="fa-solid fa-lock-open" :title="$t('history.listview.listitems.buttons.set_password_tooltip')"></i>

                            </a>
                        </div>
                    </div>
                </div>-->
                <!--</div>
                </div>-->

            </div>
        </div>
        <div v-else class="site-content">

            {{ $t('history.no_content')}}
            <div class="new-upload-div">
                <div>{{ $t('download.new_upload.text_before')}}<a @click="globals.goto_new_upload()">{{ $t('download.new_upload.link')}}</a>{{ $t('download.new_upload.text_after')}}</div>
            </div>
        </div>
    </div>

    <div id="set_new_password" v-if="this.is_set_new_password_dialog_visible > 0" class="dialog-modal">
        <div class="dialog-container">
            <div class="page_dialog fadeIn less-wide">
                <div class="close-button-bar">
                    <div class="button square toolbar" @click="show_main()"><i class="fa-solid fa-xmark"></i></div>
                </div>

                <div class="site-title less-wide" v-if="this.is_set_new_password_dialog_visible == 1">{{ $t('history.set_new_password_dialog.headline_1')}}</div>
                <div class="site-title less-wide" v-if="this.is_set_new_password_dialog_visible >= 2">{{ $t('history.set_new_password_dialog.headline_2')}}</div>
                <div class="site-content less-wide" v-if="this.is_set_new_password_dialog_visible == 1">{{ $t('history.set_new_password_dialog.infotext_1')}}</div>
                <div class="site-content less-wide" v-if="this.is_set_new_password_dialog_visible == 2">{{ $t('history.set_new_password_dialog.infotext_2')}}</div>
                <form v-on:submit.prevent="send_new_password" class="bodyform less-wide">
                    <div class="bodyform__item">
                        <label for="new-password">{{ $t('history.set_new_password_dialog.form.labels.password')}}</label>
                        <input id="new-password" name="new-password" type="password" v-model="new_password" autocomplete="new-password" />
                    </div>
                    <div class="bodyform__item">
                        <label for="confirm_new-password">{{ $t('history.set_new_password_dialog.form.labels.password_confirm')}}</label>
                        <input id="confirm_new-password" name="confirm_new-password" type="password" v-model="confirm_new_password" autocomplete="new-password" />
                    </div>
                    <div class="bodyform__item two_buttons">
                        <button class="button toolbar" type="button" @click="show_main()">{{ $t('history.set_new_password_dialog.form.labels.cancel')}}</button>
                        <button class="button" type="submit">{{ $t('history.set_new_password_dialog.form.labels.submit')}}</button>
                    </div>
                </form>
            </div>
        </div>
    </div>
    <div id="success_set_new_password" v-if="is_success_set_new_password_dialog_visible > 0" class="dialog-modal">
        <div class="dialog-container">
            <div class="page_dialog fadeIn less-wide">
                <div class="close-button-bar">
                    <div class="button square toolbar" @click="show_main()"><i class="fa-solid fa-xmark"></i></div>
                </div>
                <div class="site-title less-wide" v-if="is_success_set_new_password_dialog_visible==1">{{ $t('history.set_new_password_dialog.success_dialog.headline_1')}}</div>
                <div class="site-title less-wide" v-if="is_success_set_new_password_dialog_visible==2">{{ $t('history.set_new_password_dialog.success_dialog.headline_2')}}</div>
                <div class="site-title less-wide" v-if="is_success_set_new_password_dialog_visible==3">{{ $t('history.set_new_password_dialog.success_dialog.headline_3')}}</div>
                <div class="site-content less-wide">
                    <button type="button" class="button" @click="show_main()">{{ $t('history.set_new_password_dialog.success_dialog.close_button')}}</button>
                </div>
            </div>
        </div>
    </div>
    <div id="set_new_expiry_date" v-if="this.is_set_new_expiry_date_dialog_visible" class="dialog-modal">
        <div class="dialog-container">
            <div class="page_dialog fadeIn less-wide">
                <div class="close-button-bar">
                    <div class="button square toolbar" @click="show_main()"><i class="fa-solid fa-xmark"></i></div>
                </div>
                <div class="site-title less-wide">{{ $t('history.set_new_expiry_date_dialog.headline')}}</div>
                <div class="site-content less-wide">{{ $t('history.set_new_expiry_date_dialog.infotext')}}</div>
                <form v-on:submit.prevent="send_new_expiry_date" class="bodyform less-wide">
                    <div class="bodyform__item">
                        <label for="expiry_date">{{ $t('history.set_new_expiry_date_dialog.form.labels.expiry_date')}}</label>
                        <input id="expiry_date" ref="expiry_date" name="expiry_date" type="datetime" v-model="new_expiry_date" />
                    </div>
                    <div class="bodyform__item two_buttons">
                        <button class="button toolbar" type="button" @click="show_main()">{{ $t('history.set_new_expiry_date_dialog.form.labels.cancel')}}</button>
                        <button style="float: left;" type="submit" class="button">{{ $t('history.set_new_expiry_date_dialog.form.labels.submit')}}</button>
                    </div>
                </form>
            </div>
        </div>
    </div>
    <div id="success_set_new_expiry_date" v-if="is_success_set_new_expiry_date_dialog_visible" class="dialog-modal">
        <div class="dialog-container">
            <div class="page_dialog fadeIn less-wide">
                <div class="close-button-bar">
                    <div class="button square toolbar" @click="show_main()"><i class="fa-solid fa-xmark"></i></div>
                </div>
                <div class="site-title less-wide">{{ $t('history.set_new_expiry_date_dialog.success_dialog.headline')}}</div>
                <div class="site-content less-wide">
                    <button type="button" class="button" @click="is_success_set_new_expiry_date_dialog_visible = false;">{{ $t('history.set_new_expiry_date_dialog.success_dialog.close_button')}}</button>
                </div>
            </div>
        </div>
    </div>
    <div id="log_dialog" v-if="this.is_log_dialog_visible" class="dialog-modal">
        <div class="dialog-container">
            <div class="page_dialog fadeIn less-wide">
                <div class="close-button-bar">
                    <div class="button square toolbar" @click="show_main()"><i class="fa-solid fa-xmark"></i></div>
                </div>
                <div class="site-title less-wide">{{ $t('history.listview.history_logs.headline')}}</div>
                <!--<div class="site-content less-wide">{{ $t('history.listview.history_logs.infotext')}}</div>-->
                <div class="site-content less-wide">
                    <div class="logs-table scrollable">
                        <div v-for="(history_log, index) in history_logs" :key="index" class="logs-body-row" :class="{'odd': index%2!=0}">
                            <div class="logs-body-column date">{{helper.formatReadableDate(history_log.date, globals.selected_language, { weekday: 'short', month: '2-digit' })}} </div>
                            <div class="logs-body-column event"> {{history_log.label}}</div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
<style>
</style>

<script>
    import FileShare from '../components/FileShare.vue'
    import CustomDropdown from '../components/CustomDropdown.vue';

    import helper from '../utils/Helper';
    import globals from '../utils/Globals';
    import security from '../utils/Security';
 
    export default {
        name: 'History',
        setup() {
            return {
                helper, globals, security
            };
        },
        components: {
            FileShare,
            CustomDropdown,
        },
        props: {
            file_share_id: '',
        },
        data: () => ({
            current_download: '',
            history_logs: [],

            is_set_new_password_dialog_visible: 0,
            is_success_set_new_password_dialog_visible: 0,
            is_set_new_expiry_date_dialog_visible: false,
            is_success_set_new_expiry_date_dialog_visible: false,
            is_log_dialog_visible: false,
       

            confirm_new_password: '',
            new_password: '',

            new_expiry_date: '',
            old_expiry_date: '',

            flatpickrOb: null,

            history_items: [],
            sortOptions: [],
            sortBy: "",
            sortDirection:"",
           
        }),
        computed: {
            filterHistoryItems() {
                let workingList = this.history_items;
                if (this.sortBy) {
                    if (this.sortBy === 'created_on')
                        workingList = workingList.sort((a, b) => {
                            const comparison = new Date(a.created_on) - new Date(b.created_on);
                            return this.sortDirection === 'ASC' ? comparison : -comparison;
                        });
                    else if (this.sortBy === 'expiry_date')
                        workingList = workingList.sort((a, b) => {
                            const comparison = new Date(a.expiry_date) - new Date(b.expiry_date);
                            return this.sortDirection === 'ASC' ? comparison : -comparison;
                        });
                    else if (this.sortBy === 'subject')
                        workingList = workingList.sort((a, b) => {
                            const comparison = (a.subject_text + "").localeCompare(b.subject_text + "");
                            return this.sortDirection === 'ASC' ? comparison : -comparison;
                        });
                    else if (this.sortBy === 'file_count')
                        workingList = workingList.sort((a, b) => {
                            const comparison = a.files.length - b.files.length;
                            return this.sortDirection === 'ASC' ? comparison : -comparison;
                        });
                }
                return workingList;
            }
            
        },
        methods: {
            __sumDownloads(download_stats) {
                    return download_stats.reduce((acc, download_stat) => { return acc + download_stat.download_counter; }, 0);
                
            },
            handleSortSelected(selectedOption) {
                //console.log(selectedOption);
                //console.log(selectedOption.value);
                this.sortBy = selectedOption.value.name;
                this.sortDirection = selectedOption.value.order;
            },
            async get_download_history() {
                this.history_items = [];

                this.sortOptions =
                    [
                        { label: 'history.listview.toolbarpanel.sort.options.by_file_count_asc', value: { name: "file_count", order: "ASC" } },
                        { label: 'history.listview.toolbarpanel.sort.options.by_file_count_desc', value: { name: "file_count", order: "DESC" } },
                        { label: 'history.listview.toolbarpanel.sort.options.by_created_on_asc', value: { name: "created_on", order: "ASC" } },
                        { label: 'history.listview.toolbarpanel.sort.options.by_created_on_desc', value: { name: "created_on", order: "DESC" } },
                        { label: 'history.listview.toolbarpanel.sort.options.by_expiry_date_asc', value: { name: "expiry_date", order: "ASC" } },
                        { label: 'history.listview.toolbarpanel.sort.options.by_expiry_date_desc', value: { name: "expiry_date", order: "DESC" } },
                        { label: 'history.listview.toolbarpanel.sort.options.by_subject_asc', value: { name: "subject", order: "ASC" } },
                        { label: 'history.listview.toolbarpanel.sort.options.by_subject_desc', value: { name: "subject", order: "DESC" } },

                    ];
                const url = "download/history/" + globals.service
                try {
                    const response = await this.globals.make_Xhr_Request("GET", url);
                    if (response.status === 200) {
                        let result = JSON.parse(response.responseText);
                        let decryted_dbKeys = [];
                        // Externe Schleife für download_units
                        this.history_items = result.payload.download_units;
                    } else {
                        globals.handle_Xhr_Errors(response.status, response.responseText);
                    }
                } catch (e) {
                    console.error("get_download_history failed", e);
                }
                finally {
                    globals.hide_loading();
                }
            },
            async show_logs(event) {
                this.history_logs = [];
                this.current_download = event.target.parentElement.getAttribute('data-id');
                if (this.current_download) {
                    let current_history_item = this.history_items.find(d => d.id == this.current_download);
                    //console.log("current_history_item", current_history_item);
                    this.history_logs.push({
                        label: this.$i18next.t('history.listview.history_logs.labels.created_on'), date: current_history_item.created_on });

                    for (var i = 0; i < current_history_item.share_tokens.length; i++) {
                        let shareToken = current_history_item.share_tokens[i];
                        if (shareToken.first_opened_on) {
                            if (shareToken.email) {
                                this.history_logs.push({ label: this.$i18next.t('history.listview.history_logs.labels.first_opened_on_email', { email: shareToken.email }), date: shareToken.first_opened_on });
                            } else {
                                this.history_logs.push({ label: this.$i18next.t('history.listview.history_logs.labels.first_opened_on_link'), date: shareToken.first_opened_on });
                            }
                        }
                    }
                    let files = await security.supplement_download_content(current_history_item);

                    for (var i = 0; i < files.length; i++) {
                        let f = files[i];
                        if (f.download_stats && f.download_stats.length > 0) {
                            for (var j = 0; j < f.download_stats.length; j++) {

                                let ds = f.download_stats[j];
                                let st = current_history_item.share_tokens.find(d => d.token === ds.token);
                                //console.log("st", st);
                                if (st.email) {
                                    this.history_logs.push({ label: this.$i18next.t('history.listview.history_logs.labels.first_downloaded_on_email', { file: f.name, email: st.email, download_count: ds.download_counter }), date: ds.first_downloaded_on });
                                } else {
                                    this.history_logs.push({ label: this.$i18next.t('history.listview.history_logs.labels.first_downloaded_on_link', { file: f.name, download_count: ds.download_counter }), date: ds.first_downloaded_on });
                                }
                            }
                        } 
                    }
                    
                    this.history_logs = this.history_logs.sort((a, b) => {
                        const comparison = new Date(a.date) - new Date(b.date);
                        //return this.sortDirection === 'ASC' ? comparison : -comparison;
                        return comparison;
                    });

                    //console.log(this.history_logs);
                    this.is_log_dialog_visible = true;
                }

            },
            async delete_download(event) {
                let id = event.target.parentElement.getAttribute('data-id');
                if (id && confirm(this.$i18next.t('history.confirmations.delete_download'))) {
                    const url = "download/delete/" + id;
                    try {
                        const response = await globals.make_Xhr_Request("DELETE", url);
                        if (response.status === 200) {
                            await this.get_download_history();
                        } else {
                            globals.handle_Xhr_Errors(response.status, response.responseText);
                        }
                    } catch (e) {
                        console.error("delete_download failed", e);
                    }
                    finally {
                        globals.hide_loading();
                    }
                }
            },
            show_file_share_view(event) {
                this.current_download = event.target.parentElement.getAttribute('data-id');
                if (this.current_download) {
                    this.$router.push({
                        name: 'History_Details',
                        params: { file_share_id: this.current_download },
                    });                    
                }
            },
            show_main() {
                this.current_download = '';
                this.is_set_new_expiry_date_dialog_visible = false;
                this.is_set_new_password_dialog_visible = 0;
                this.is_success_set_new_expiry_date_dialog_visible = false;
                this.is_success_set_new_password_dialog_visible = false;
                this.is_log_dialog_visible = false;
                this.is_main_view_visible = true;                
                globals.setPageTitel("history.headline");
            },
            show_set_new_password_dialog(event) {
                this.current_download = event.target.parentElement.getAttribute('data-id');
                if (this.current_download) {
                    let hasPw = event.target.parentElement.getAttribute('data-has-password');
                    this.is_set_new_password_dialog_visible = hasPw === 'true' ? 2 : 1;
                }
            },
            async send_new_password() {
                if ((this.new_password + "") !== (this.confirm_new_password + "")) {
                    globals.show_error('errors.password_not_match');
                    return;
                }
                
                if ((this.new_password + "") === "") {
                    if (!confirm(this.$i18next.t('history.set_new_password_dialog.confirmation'))) {
                        return;
                    }
                    this.is_set_new_password_dialog_visible = 3;
                }
                const url = "download/reset_password";
                const data = { "id": this.current_download, "password": this.new_password };
                try {
                    const response = await globals.make_Xhr_Request("PATCH", url, data);
                    if (response.status === 200) {
                        this.is_success_set_new_password_dialog_visible = this.is_set_new_password_dialog_visible;
                        this.is_set_new_password_dialog_visible = 0;
                        await this.get_download_history();
                    } else if (response.status === 404) {
                        globals.show_error('errors.unknown_datarecord');
                    } else {
                        globals.handle_Xhr_Errors(response.status, response.responseText);
                    }
                } catch (e) {
                    console.error("delete_download failed", e);
                }
                finally {
                    globals.hide_loading();
                }
            },
            show_set_new_expiry_date_dialog(event) {
                this.current_download = event.target.parentElement.getAttribute('data-id');
                this.old_expiry_date = new Date(event.target.parentElement.getAttribute('data-expiry-date'));
                if (this.current_download) {
                    this.is_set_new_expiry_date_dialog_visible = true;
                }
            },
            async send_new_expiry_date() {

                let theCurrentItem = this.history_items.find(d => d.id == this.current_download);
                if (!theCurrentItem) {
                    globals.show_error('errors.unknown_datarecord');
                    return;
                }
                const url = "download/change_download";
                var data = {
                    "id": theCurrentItem.id,
                    "expiry_date": new Date(this.new_expiry_date),
                    "subject": theCurrentItem.subject_text,
                    "message": theCurrentItem.message_text
                };
                
                try {
                    const response = await globals.make_Xhr_Request("PATCH", url, data);
                    if (response.status === 200) {
                        this.is_success_set_new_expiry_date_dialog_visible = true;
                        this.is_set_new_expiry_date_dialog_visible = false;
                        await this.get_download_history();
                    } else {
                        globals.handle_Xhr_Errors(response.status, response.responseText);
                    }
                } catch (e) {
                    console.error("delete_download failed", e);
                }
                finally {
                    globals.hide_loading();
                } 
            },
            handleKeyDown(event) {
                if (event.key === 'Escape') {
                    if (this.is_set_new_password_dialog_visible > 0) {
                        this.show_main();
                    }
                    else if (this.is_success_set_new_password_dialog_visible > 0) {
                        this.show_main();
                    }
                    else if (this.is_set_new_expiry_date_dialog_visible) {
                        this.show_main();
                    }
                    else if (this.is_success_set_new_expiry_date_dialog_visible) {
                        this.show_main();
                    }
                    else if (this.is_log_dialog_visible) {
                        this.show_main();
                    }
                }
            },
            initializeFlatpickr(dateBox) {
                const locale = globals.selected_language === 'de' ? flatpickr.l10ns.de : flatpickr.l10ns.default;
                const dateFormat = globals.selected_language === 'de' ? 'd. M Y H:i' : 'M/d/Y h:i K';

                this.flatpickrOb = flatpickr(dateBox, {
                    enableTime: true,
                    minDate: "today",
                    maxDate: new Date().fp_incr(7),
                    locale: locale,
                    dateFormat: dateFormat,
                });
            },
            async show_me() {
                globals.setPageTitel("history.headline");
                await this.get_download_history();
            }
        },
        watch: {
            'globals.selected_language': {
                handler: function (newVal, oldVal) {
                    if (this.flatpickrOb !== null) {
                        this.flatpickrOb.destroy();
                        this.initializeFlatpickr(this.dateBox);
                    }
                },
                immediate: true
            },
            'globals.loggedIn': {
                handler: async function () {
                    await this.show_me();
                }
            }
        },
        mounted: async function () {
            await this.show_me();
            document.addEventListener('keydown', this.handleKeyDown);
        },
        beforeDestroy() {
            document.removeEventListener('keydown', this.handleKeyDown);
        }, 
        updated: async function () {
            if (!this.is_set_new_expiry_date_dialog_visible) {
                this.new_expiry_date = '';
                this.old_expiry_date = '';
                this.flatpickrOb = null;
            }

            if (this.$refs.expiry_date && !this.flatpickrOb) {
                this.dateBox = this.$refs.expiry_date;
                this.initializeFlatpickr(this.dateBox);
                if (this.old_expiry_date) {
                    this.flatpickrOb.setDate(this.old_expiry_date);
                }

            }
        }
    }
</script>
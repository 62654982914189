<template>
    <MarketingPanel></MarketingPanel>
    <div id="download" v-if="!this.password_required">
        <div class="site-title">{{ $t('download.headline')}}</div>
        <div v-if="!this.password_required" class="site-content">
            <FileShare v-if="this.current_file_share && this.share_pw" :fileShareInfos="this.current_file_share" :share_pw="share_pw"></FileShare>
        </div>
        <div class="site-content">
            <div class="content">
                <button type="button" class="button wide" @click="globals.goto_new_upload();">{{ $t('download.new_upload.button')}}</button>
            </div>
        </div>
    </div>
    <div id="password-required" v-if="this.password_required">
        <div class="site-title">{{ $t('download.password_required_dialog.headline')}}</div>
        <div class="site-content">

            <form v-on:submit.prevent="get_download_content_with_password" class="bodyform">
                <div class="bodyform__item">
                    <label for="download_password">{{ $t('download.password_required_dialog.form.labels.password')}}</label>
                    <input id="download_password" name="download_password" type="password" v-model="download_password" />
                </div>
                <div class="bodyform__item">
                    <button class="button" type="submit">{{ $t('download.password_required_dialog.form.labels.submit')}}</button>
                </div>
            </form>

        </div>
    </div>
</template>
<style>
    
</style>

<script>
    import MarketingPanel from '../components/MarketingPanel.vue';
    import CustomDropdown from '../components/CustomDropdown.vue';
    import FileShare from '../components/FileShare.vue'

    import helper from '../utils/Helper';
    import globals from '../utils/Globals';
    import security from '../utils/Security';

    export default {
        name: 'Download',
        setup() {
            return {
                helper, globals, security
            };
        },
        components: {
            CustomDropdown,
            MarketingPanel,
            FileShare
        },
        props: {
            token: '',
        },
        data: () => ({
            current_file_share: '',
            download_password: '',
            password_required: false,            
            share_token: '',
            share_pw: '',
        }),
        computed: {
        },
        methods: {
            async get_download_content() {
                const url = "download/" + this.share_token;
                try {
                    const response = await this.globals.make_Xhr_Request("GET", url);
                    if (response.status === 200) {
                        var result = JSON.parse(response.responseText);
                        this.current_file_share = result.payload;
                    } else if (response.status === 401) {
                        this.password_required = true;
                    } else if (response.status === 408) {
                        globals.show_error('errors.files_expired');
                    } else if (response.status === 400) {
                        globals.show_error('errors.invalid_link');
                    } else if (response.status === 404) {
                        globals.show_error('errors.files_not_found');
                    } else {
                        this.globals.handle_Xhr_Errors(response.status, response.responseText);
                    }
                } catch (e) {
                    console.error("get_download_content failed", e);
                }
                finally {
                    this.globals.hide_loading();
                }
            },
            async get_download_content_with_password() {

                const url = "download";
                const data = {
                    id: this.share_token,
                    password: this.download_password,
                };
                try {
                    const response = await this.globals.make_Xhr_Request("POST", url, data);
                    if (response.status === 200) {
                        var result = JSON.parse(response.responseText);
                        //console.log("result.payload #2", result.payload);
                        this.current_file_share = result.payload;
                        this.password_required = false;
                    } else if (response.status === 401) {
                        this.password_required = true;
                        globals.show_error('errors.invalid_password');
                    } else if (response.status === 408) {
                        globals.show_error('errors.files_expired');
                    } else if (response.status === 400) {
                        globals.show_error('errors.invalid_link');
                    } else if (response.status === 404) {
                        globals.show_error('errors.files_not_found');
                    } else {
                        this.globals.handle_Xhr_Errors(response.status, response.responseText);
                    }
                } catch (e) {
                    console.error("get_download_content_with_password failed", e);
                }
                finally {
                    this.globals.hide_loading();
                }
            },
        },
        mounted: async function ()
        {
            if (this.token) {
                this.share_token = this.token;
                globals.setPageTitel("download.headline");
                if (this.$route.name === 'SecureShare') {
                    try {
                        const r = security.decode_secure_share_token(this.share_token);
                        this.share_token = r.share_token;
                        this.share_pw = r.share_pw;
                    } catch (e) {
                        globals.show_error("errors.invalid_code");
                        return;
                    }
                }
                await this.get_download_content();
            } else {
                this.$router.push('/');
            }
        }
    }
</script>
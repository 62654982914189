<template>
    <footer>
        <div class="site-container footer-content">
            <div class="footer-description" v-html="$t('footer.description')"></div>
            <div class="footer-bar">
                <nav>
                    <span v-html="$t('footer.copyright', { year: new Date().getFullYear() })"></span> |
                    <span v-html="$t('footer.links.features')"></span> |
                    <span v-html="$t('footer.links.pricing')"></span> |
                    <span v-html="$t('footer.links.privacy')"></span> |
                    <span v-html="$t('footer.links.terms')"></span> |
                    <span v-html="$t('footer.links.imprint')"></span>
                </nav>
                <div id="languageselector">
                    <div v-if="globals.available_languages">
                        <span v-for="(lng, index) in Object.keys(globals.available_languages)" :key="lng">
                            <a v-if="$i18next.resolvedLanguage !== lng" v-on:click="globals.change_language(lng);">
                                {{ globals.available_languages[lng].nativeName }}
                            </a>
                            <strong v-if="$i18next.resolvedLanguage === lng">
                                {{ globals.available_languages[lng].nativeName }}
                            </strong>
                            <span v-if="index < (Object.keys(globals.available_languages).length - 1)">&nbsp;|&nbsp;</span>
                        </span>
                        <!--<div v-for="(lng, index) in Object.keys(globals.available_languages)" :key="lng" style="display:inline; padding: 0 4px;">
                            <a v-on:click="globals.change_language(lng);" :title="globals.available_languages[lng].nativeName">
                                <div :class="'flag-' + globals.available_languages[lng].internalName">
                                    {{ globals.available_languages[lng].nativeName }}
                                </div>
                            </a>
                        </div>-->
                    </div>
                </div>
            </div>            
        </div>
    </footer>
</template>
<script>
    import globals from '../utils/Globals';
    export default {
        name: 'SiteFooter',
        setup() {
            return {
                globals
            };
        },
        props: {
            
        },
        data: () => ({
            
        }),
        methods: {

        },
        mounted: function () {
        },
    }
</script>
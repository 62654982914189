import { createApp } from 'vue';
import App from './App.vue';
import router from './router'; 
import '@common_assets/styles/fildeling.css';
import '@common_assets/styles/fontawesome-all.min.css';

import { initI18next } from './i18n';

import flatpickr from "flatpickr";
import 'flatpickr/dist/flatpickr.css';
import 'flatpickr/dist/l10n/de';



// Erstelle die Vue App
const app = createApp(App);

//console.log("main#1", localStorage.getItem("i18nextLng"));

// Verwende i18next in der App
initI18next(app); // Rufen Sie die Funktion auf und �bergeben Sie die Vue-App

//app.use(i18n);
//console.log("main#2", localStorage.getItem("i18nextLng"));

// Verwende Vue Router in der App
app.use(router);

// Mounte die App im DOM
app.mount('#app');